import React, { useState, useEffect } from "react";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";
import { app } from "../firebase";



const db = getFirestore(app);


function ExpenseDetails(props) {
    console.log("p",props)
    const [datatable, setDatatable] = useState([]);

    useEffect(() => {

      const fetchData = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, "expenseApprovalForm"));
          const fetchedData = querySnapshot.docs.map(doc => 
            doc.data());
          setDatatable(fetchedData)
          
          console.log("Data from Firestore:", fetchedData);
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      };
      
      
      
          fetchData();
        }, []);


  return (
    <>
    
    
    
    </>
  )
}

export default ExpenseDetails
