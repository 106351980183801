import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar, Typography, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link ,useLocation } from 'react-router-dom';
import Navbaar from './Navbaar/Navbaar';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import CodeIcon from '@mui/icons-material/Code';
import DescriptionIcon from '@mui/icons-material/Description';
import ExplicitIcon from '@mui/icons-material/Explicit';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import GrassIcon from '@mui/icons-material/Grass';
const Layout = ({ children }) => {
  const location = useLocation();

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/das' },
    { text: 'Users', icon: <PeopleIcon />, path: '/users' },
    { text: 'Approval Master', icon: <FormatColorTextIcon />, path: '/approvaltable' },
    { text: 'Expense Master', icon: <ExplicitIcon />, path: '/tab' },
    { text: 'Item Master', icon: <DescriptionIcon />, path: '/itemtable' },
    { text: 'AI Master', icon: <CodeIcon />, path: '/aaa' },
    { text: 'Approval VS Expense', icon: <WorkspacesIcon />, path: '/appvsexp' },
    { text: 'Rejected Expense', icon: <ThumbDownAltIcon />, path: '/reject' },
    { text: 'Salesforce Expense Master', icon: <AutoAwesomeIcon />, path: '/salesforce' },
    { text: 'Salesforce Approval VS Expense', icon: <GrassIcon />, path: '/salesappvsexp' },
    { text: 'Salesforce Rejected Expense', icon: <Diversity2Icon />, path: '/salesforceprocess' },

  ];



  return (
    // <Box sx={{ display: 'flex' }}>
    //   {/* <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    //     <Toolbar>
    //         <img src="" alt="" />
    //       <Typography variant="h6" noWrap>
    //         Admin Dashboard
    //       </Typography>
    //     </Toolbar>
    //   </AppBar> */}
      
    //   <Drawer
    //     variant="permanent"
    //     sx={{
    //       width: 240,
        
    //       flexShrink: 0,
    //       [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box',  marginTop:"110px", },
    //     }}
    //   >
    //     {/* <Toolbar /> */}
    //     <Box mt={1} sx={{ overflow: 'auto' }}>
    //       <List>
    //         <ListItem button 
            
    //         sx={({ isActive }) => {
    //           return {
    //             // borderBottom: isActive ? "2px solid #ebe956" : "2px solid #007a48",
    //             textDecoration: "none",
    //             color: isActive ? "#ebe956" : "red",
    //           };
    //         }}
            
    //         component={Link} to="/das">
    //           <ListItemIcon>
    //             <DashboardIcon />
    //           </ListItemIcon>
    //           <ListItemText primary="Dashboard" />
    //         </ListItem>
    //         <ListItem button
            
    //         sx={({ isActive }) => {
    //           return {
    //             // borderBottom: isActive ? "2px solid #ebe956" : "2px solid #007a48",
    //             textDecoration: "none",
    //             color: isActive ? "#ebe956" : "red",
    //           };
    //         }}
    //         component={Link} to="/users">
    //           <ListItemIcon>
    //             <PeopleIcon />
    //           </ListItemIcon>
    //           <ListItemText primary="Users" />
    //         </ListItem>
    //         <ListItem button component={Link} to="/tab">
    //           <ListItemIcon>
    //             <FormatColorTextIcon />
    //           </ListItemIcon>
    //           <ListItemText primary="Approval Master" />
    //         </ListItem>
    //         <ListItem button component={Link} to="/approvaltable">
    //           <ListItemIcon>
    //           <ExplicitIcon />
               
    //           </ListItemIcon>
    //           <ListItemText primary="Expense Master" />
    //         </ListItem>
    //         <ListItem button component={Link} to="/itemtable">
    //           <ListItemIcon>
    //           <DescriptionIcon />
    //           </ListItemIcon>
    //           <ListItemText primary="Item Master" />
    //         </ListItem> 
    //          <ListItem button component={Link} to="/tab">
    //           <ListItemIcon>
    //             <CodeIcon />
    //           </ListItemIcon>
    //           <ListItemText primary="AI Master" />
    //         </ListItem>
    //         <ListItem button component={Link} to="/appvsexp">
    //           <ListItemIcon>
    //             <WorkspacesIcon />
    //           </ListItemIcon>
    //           <ListItemText primary="Approval VS Expense" />
    //         </ListItem>
    //         <ListItem button component={Link} to="/reject">
    //           <ListItemIcon>
    //             <ThumbDownAltIcon />
    //           </ListItemIcon>
    //           <ListItemText primary="Rejected Expense" />
    //         </ListItem>
    //       </List>
    //     </Box>
    //   </Drawer>
    //   <Box
    //     component="main"
    //     sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
    //   >
    //     <Toolbar />
    //     {children}
    //   </Box>
    // </Box>









    <Box sx={{ display: 'flex' }}>
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box', marginTop: '110px' },
      }}
    >
      <Box mt={1} sx={{ overflow: 'auto' }}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={index}
              component={Link}
              to={item.path}
              sx={{
                textDecoration: 'none',
                color: location.pathname === item.path ? '#fcb900' : '#696969',
                backgroundColor:location.pathname === item.path ? '#ffffd4' : '#ffffff'
                // Add more styles if needed
              }}
            >
              <ListItemIcon sx={{ color: location.pathname === item.path ? '#fcb900' : '#696969' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
    <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
      <Toolbar />
      {children}
    </Box>
  </Box>





























  );
};

export default Layout;
