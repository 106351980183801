// import { grey } from "@mui/material/colors";
// import React, { useState, useEffect } from "react";
// import { Chart } from "react-google-charts";
// import { app } from "../firebase";
// import { getFirestore, collection, addDoc, getDocs,query,where,orderBy } from "firebase/firestore";
// import Navbaar from "./Navbaar/Navbaar";
// const db = getFirestore(app);

// // const data = [
// //   ["Brand", "Expense Per Month"],
// //   ["Pinch", 11],
// //   ["D2C", 2],
// //   ["Firebrick", 2],
// //   ["Wellserved", 2],
// //   ["1 To Zee", 7],
// //   ["CARECREW", 1],
// //   ["Gulmohar",1]
// // ];

// const options = {
//   title: "My Monthly Expense",

//   colors : ['#ffbd56','#f9a541',"#003F5C" ,"#ee8522", "#ef5f24","#ee8522" , "#4a4b4b","#011139",]
// }

   
  
  

// function PieChart() {


//   const [datatable, setDatatable] = useState([["Brand", "Expense Per Month"]]);
//   const [data, setData] = useState([["Brand", "Totel Expense"]]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const que= query(collection(db, "expenseApprovalForm"), orderBy('timeStamp',"desc"),
//          where("approvalStatus", "==", "financeApproved"),
//         //  where("monthOfExpense", "==", "june")
//         );
//         const querySnapshot=await getDocs(que);
//         // const querySnapshot = await getDocs(
//         //   collection(db, "expenseApprovalForm")
//         // );
//         const fetchedData = querySnapshot.docs.map((doc) => doc.data());

//         const expenseMap = {};
//         fetchedData.forEach((doc) => {
//           if (expenseMap[doc.brand]) {
//             expenseMap[doc.brand] += doc.totalAmount;
//           } else {
//             expenseMap[doc.brand] = doc.totalAmount;
//           }
//         });

//         const chartData = [["Brand", "totral Expense"]];
//         for (const [brand, expense] of Object.entries(expenseMap)) {
//           chartData.push([brand, expense]);
//         }
//         setDatatable(chartData);
//         setData(chartData)


//         console.log("Data from chartbar:", chartData);
//       } catch (err) {
//         console.error("Error fetching data:", err);
//       }
//     };

//     fetchData();
//   }, []);


//   return (
//     <div>
        
//         <Chart
//       chartType="PieChart"
//       data={data}
//       options={options}
//       width={"100%"}
//       height={"400px"}
     
//     />

//     </div>
//   )
// }

// export default PieChart








































































// import React, { useState, useEffect } from "react";
// import { Chart } from "react-google-charts";
// import { app } from "../firebase";
// import { getFirestore, collection, query, where, orderBy, getDocs } from "firebase/firestore";
// import Navbaar from "./Navbaar/Navbaar";
// import { Container, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// const db = getFirestore(app);

// const options = {
//   title: "My Monthly Expense",
//   colors: ['#ffbd56', '#f9a541', "#ee8522", "#ef5f24", "#ee8522", "#4a4b4b", "#011139"],
// };

// function PieChart() {
//   const [data, setData] = useState([["Brand", "Expense Per Month"]]);
//   const [filter, setFilter] = useState("month");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         let que;
//         const currentTime = new Date();
        
//         if (filter === "month") {
//           const firstDayOfMonth = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1);
//           que = query(
//             collection(db, "expenseApprovalForm"),
//             orderBy("timeStamp", "desc"),
//             where("approvalStatus", "==", "financeApproved"),
//             where("timeStamp", ">=", firstDayOfMonth)
//           );
//         } else if (filter === "week") {
//           const firstDayOfWeek = new Date(currentTime.setDate(currentTime.getDate() - currentTime.getDay()));
//           que = query(
//             collection(db, "expenseApprovalForm"),
//             orderBy("timeStamp", "desc"),
//             where("approvalStatus", "==", "financeApproved"),
//             where("timeStamp", ">=", firstDayOfWeek)
//           );
//         } else if (filter === "year") {
//           const firstDayOfYear = new Date(currentTime.getFullYear(), 0, 1);
//           que = query(
//             collection(db, "expenseApprovalForm"),
//             orderBy("timeStamp", "desc"),
//             where("approvalStatus", "==", "financeApproved"),
//             where("timeStamp", ">=", firstDayOfYear)
//           );
//         }

//         const querySnapshot = await getDocs(que);
//         const fetchedData = querySnapshot.docs.map((doc) => doc.data());

//         // Aggregate expenses by brand
//         const expenseMap = {};
//         fetchedData.forEach((doc) => {
//           if (expenseMap[doc.brand]) {
//             expenseMap[doc.brand] += doc.totalAmount;
//           } else {
//             expenseMap[doc.brand] = doc.totalAmount;
//           }
//         });

//         // Prepare data for Google Chart
//         const chartData = [["Brand", "Expense Per Month"]];
//         for (const [brand, expense] of Object.entries(expenseMap)) {
//           chartData.push([brand, expense]);
//         }

//         setData(chartData);

//         console.log("Data from Firestore:", fetchedData);
//         console.log("Aggregated Chart Data:", chartData);
//       } catch (err) {
//         console.error("Error fetching data:", err);
//       }
//     };

//     fetchData();
//   }, [filter]);

//   return (
//     <Container sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
//        <ToggleButtonGroup
//        sx={{ height:"14px", width:"50px",  }}
//       color="warning"
//      value={filter}
//          // label="Filter"
//       // exclusive
//       onChange={(e) => setFilter(e.target.value)}
//       aria-label="Platform"
//     >
//        <ToggleButton value="week">Week</ToggleButton>
//       <ToggleButton value="month">Month</ToggleButton>
     
//       <ToggleButton value="year">Year</ToggleButton>
//     </ToggleButtonGroup>
//       <Box sx={{ display: 'flex', justifyContent: 'left', mt:2, ml:"-100px"}}>
//         <Chart
//           chartType="PieChart"
//           data={data}
//           options={options}
//           width={"99%"}
//           height={"350px"}
//         />
//       </Box>
//     </Container>
//   );
// }

// export default PieChart;



import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { app } from "../firebase";
import { getFirestore, collection, query, where, orderBy, getDocs } from "firebase/firestore";
import Navbaar from "./Navbaar/Navbaar";
import { Container, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';

const db = getFirestore(app);

const options = {
  title: "My Monthly Expense",
  colors: ['#ffbd56', '#f9a541', "#ee8522", "#ef5f24", "#ee8522", "#4a4b4b", "#011139"],
};

function PieChart() {
  const [data, setData] = useState([["Brand", "Expense Per Month"]]);
  const [filter, setFilter] = useState("month");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let que;
        const currentTime = new Date();
        
        if (filter === "month") {
          const firstDayOfMonth = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1);
          que = query(
            collection(db, "expenseApprovalForm"),
            orderBy("timeStamp", "desc"),
            where("approvalStatus", "==", "financeApproved"),
            where("timeStamp", ">=", firstDayOfMonth)
          );
        } else if (filter === "week") {
          const firstDayOfWeek = new Date(currentTime.setDate(currentTime.getDate() - currentTime.getDay()));
          que = query(
            collection(db, "expenseApprovalForm"),
            orderBy("timeStamp", "desc"),
            where("approvalStatus", "==", "financeApproved"),
            where("timeStamp", ">=", firstDayOfWeek)
          );
        } else if (filter === "year") {
          const firstDayOfYear = new Date(currentTime.getFullYear(), 0, 1);
          que = query(
            collection(db, "expenseApprovalForm"),
            orderBy("timeStamp", "desc"),
            where("approvalStatus", "==", "financeApproved"),
            where("timeStamp", ">=", firstDayOfYear)
          );
        }

        const querySnapshot = await getDocs(que);
        const fetchedData = querySnapshot.docs.map((doc) => doc.data());

        // Aggregate expenses by brand
        const expenseMap = {};
        fetchedData.forEach((doc) => {
          if (expenseMap[doc.brand]) {
            expenseMap[doc.brand] += doc.totalAmount;
          } else {
            expenseMap[doc.brand] = doc.totalAmount;
          }
        });

        // Prepare data for Google Chart
        const chartData = [["Brand", "Expense Per Month"]];
        for (const [brand, expense] of Object.entries(expenseMap)) {
          chartData.push([brand, expense]);
        }

        setData(chartData);

        console.log("Data from Firestore:", fetchedData);
        console.log("Aggregated Chart Data:", chartData);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [filter]);

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  return (
    <Container sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
    
      <ToggleButtonGroup
        sx={{ mt: 2,height:"14px", width:"50px",  }}
        color="warning"
        value={filter}
        exclusive
        onChange={handleFilterChange}
        aria-label="Filter"
      >
        <ToggleButton value="week">Week</ToggleButton>
        <ToggleButton value="month">Month</ToggleButton>
        <ToggleButton value="year">Year</ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{ display: 'flex', justifyContent: 'left', mt: 2 }}>
        <Chart
          chartType="PieChart"
          data={data}
          options={options}
          width={"99%"}
          height={"350px"}
        />
      </Box>
    </Container>
  );
}

export default PieChart;