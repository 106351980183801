import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  useGridApiEventHandler,
  GridToolbar,
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { app } from "../firebase";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  where,
  query,
  orderBy
} from "firebase/firestore";
import Navbaar from "./Navbaar/Navbaar";
import Layout from "./Layout";
const db = getFirestore(app);


function HawkApprovalDatabasetable() {

    const [itmizedDataTable, setItmizedDataTable] = useState([]);
    const [res, setRes] = useState({});
    const [invoiceImageURL, setInvoiceImageUR] = useState("");
  
    const columns = [
      {
        field: "empName",
        headerName: "Name",
        description: "Name",
        sortable: true,
        width: 110,
      },
      {
        field: "EmpEmail",
        headerName: "Email",
        width: 200,
        editable: true,
        description: "email",
      },

      {
        field: "approvalID",
        headerName: "Approval ID",
        width: 150,
        editable: true,
        description: "approvalID",
        hideable: false,
      },

    //   {
    //     field: "invoiceDate",
    //     headerName: "Invoice Date",
    //     description: "Invoice Date",
    //     sortable: false,
    //     width: 100,
    //     type: "action",
    //     renderCell: (params) => {
    //       const timestamp = params?.value?.seconds * 1000;
    //       // Convert the timestamp to a Date object
    //       const date = new Date(timestamp);
    //       // Format the date as needed
    //       const formattedDate = date.toISOString().split("T")[0];
    //       // Render the formatted date
    //       return <p> {formattedDate}</p>;
    //     },
    //   },
    {
      field: "dateOfApproval",
      headerName: "Date Of Approval",
      description: "Date Of Approval",
      sortable: false,
      width: 200,
      type: "action",
      renderCell: (params) => {
        const timestamp = params?.value?.seconds * 1000;
        // Convert the timestamp to a Date object
        const date = new Date(timestamp);
        // Format the date as needed
        const formattedDate = date.toLocaleDateString('en-GB');

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        // Format the time as needed

        const formattedTime = `${hours}:${minutes}:${seconds}`;
        // Render the formatted date
        return (
          <p>
            {formattedDate} {formattedTime}
          </p>
        );
      },
    },
      {
        field: "typeofPurchase" ,
        headerName: "typeofPurchase",
        width: 130,
        editable: true,
        description: "Type Of Purchase",
      },
   
      {
        field: "brandName",
        headerName: "Brand",
        width: 110,
        editable: true,
        description: "Brand",
      },
      {
        field: "SubBrandName",
        headerName: "Sub Brand",
        width: 110,
        editable: true,
        description: "Sub Brand",
      },
      {
        field: "location",
        headerName: "Location",
        width: 140,
        editable: true,
        description: "Location",
      },
      {
        field: "department",
        headerName: "Department",
        width: 120,
        editable: true,
        description: "Department",
      },
      {
        field: "expenseCategory",
        headerName: "Expense Category",
        width: 140,
        editable: true,
        description: "Expense Category",
      },
      {
        field: "file",
        headerName: "Show Approval Image",
        description: "For Show Your Bill",
        sortable: false,
        width: 100,
        type: "action",
        renderCell: (field) => (
          <a
            style={{ color: "#fcb900", fontWeight: "600", cursor: "pointer" }}
            href={field.value}
            target="_blank"
          >
            {field?.value}
          </a>
        ),
      },
      {
        field: "approvalfile",
        headerName: "Show Approval PDF",
        description: "For Show Your Bill",
        sortable: false,
        width: 100,
        type: "action",
        renderCell: (field) => (
          <a
            style={{ color: "#fcb900", fontWeight: "600", cursor: "pointer" }}
            href={field.value}
            target="_blank"
          >
            {field?.value}
          </a>
        ),
      },
      {
        field: "ReasonOfPurchase",
        headerName: "Reason Of Purchase",
        width: 200,
        editable: true,
        description: "Reason Of Purchase",
      },
      {
        field: "purchaseDiscription",
        headerName: "Purchase Discription",
        width: 200,
        editable: true,
        description: "Purchase Discription",
      },
      {
        field: "provisionAmount",
        headerName: "Provision Amount",
        width: 140,
        editable: true,
        description: "Provision Amount",
      },
      {
        field: "budgetLeft",
        headerName: "Budget Left",
        width: 140,
        editable: true,
        description: "Budget Left",
      },
  
      {
        field: "budgetSpent",
        headerName: "Budget Spent",
        width: 70,
        editable: true,
        description: "Budget Spent",
      },
      {
        field: "paymentTag",
        headerName: "Payment Tag",
        width: 100,
        editable: true,
        description: "Payment Tag",
      },
      {
        field: "approvalStatus",
        headerName: "Approval Status",
        width: 140,
        editable: true,
        description: "Approval Status",
      },
      // {
      //   field: "approvedBy",
      //   headerName: "Approved By",
      //   width: 140,
      //   editable: true,
      //   description: "Approved By",
      // },
    ];
  
    //   const handleEvent =(event)=>{
    //     window.open(invoiceImageURL, "_blank", "width=600,height=400");
  
    //   }
  
    const handelclick = () => {
      navigate("/tab");
    };
    const handelclick1 = () => {
      navigate("/aaa");
    };
    const handelclick2 = () => {
      navigate("/itemtable");
    };
  
    let navigate = useNavigate();
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const que= query(collection(db, "approvalManagement"), orderBy('dateOfSubmission',"desc"),
          // where("approvalStatus", "==", "financeApproved")
          );
          const querySnapshot=await getDocs(que);
          const fetchedData = querySnapshot.docs.map((doc) => 
          ({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref.path
          }));
            //doc.data());
          setItmizedDataTable(fetchedData);
  
          console.log("Data from Firestore:", fetchedData);
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      };
  
      fetchData();
    }, []);
    console.log("tabledata", itmizedDataTable);
    console.log("tabldescriptionOfExpense", itmizedDataTable[0]?.invoiceImage);
  
  return (
    <>
    
    <Box sx={{ marginBottom: "5px" }}>
        <Navbaar />
        <Layout/>
      </Box>
     
      <Box mb={0.5} sx={{   marginTop:"-112px", display:"flex", justifyContent: "space-between", marginRight: "10px" }}>
      {/* <Box sx={{ color: "orange", fontSize:"22px", marginLeft:"10px",marginTop:"5px"}}>Approval Master Table</Box> */}
      {/* <Box sx={{ textAlign:"right",marginRight: "10px"}}> 
        <Button variant="text" color="warning" onClick={handelclick}>
          Expense Table
        </Button>
        <Button variant="text" color="warning" onClick={handelclick2}>
          Item Table
        </Button>
        <Button variant="text" color="warning" onClick={handelclick1}>
         AI Item Table List
        </Button>
        </Box> */}
      </Box>

      <Box
        p={0.5}
        ml={30}
        sx={{
          height: 656,
          width: "83%",
          backgroundColor: "#ffffff",
          minHeight: "600px",
          maxHeight: "100%",
          "& .discuss": {
            backgroundColor: "#fff44f",
            color: "#1a3e72",
          },
          "& .accept": {
            backgroundColor: "#3CB371",
            color: "#EFEFEF",
          },
          "& .reject": {
            backgroundColor: "#DD6464",
            color: "#EFEFEF",
          },
          "& .approved": {
            backgroundColor: "#ff8d1a",
            color: "#EFEFEF",
          },
          "& .prmapproved": {
            backgroundColor: "#c767fe",
            color: "#EFEFEF",
          },


          
        }}
      >
        <DataGrid
          getRowId={(row) => row.id}
          rows={itmizedDataTable ? itmizedDataTable : ""}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[500]}
          components={{ Toolbar: GridToolbar }}
          rowHeight={40}
          //  onRowClick={handleEvent}
          // initialState={{
          //   sorting: {
          //     sortModel: [
          //       {
          //         field: 'submissionDate',
          //         sort: 'desc',
          //       },
          //     ],
          //   },
          // }}
          getCellClassName={(params) => {
            if ( params.value === "Rejected") {
              return 'reject';
            }
            if ( params.value === "AllApproved") {
              return 'accept';
            }
            if ( params.value === "Pending") {
              return 'discuss';
            }
            if ( params.value === "Approved") {
              return 'approved';
            }
            if ( params.value === "prmApproved") {
              return 'prmapproved';
            }
          }}
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    
    
    </>
  )
}

export default HawkApprovalDatabasetable