import React, { useEffect, useState } from "react";
import { Button, Box, CircularProgress } from "@mui/material";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { app } from "../firebase";
//import db from "./firebase"
import { useNavigate } from "react-router-dom";
import { onSnapshot } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Navbaar from "./Navbaar/Navbaar";

const API_Key = "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";
const db = getFirestore(app);


function ItemOcr({ details }) {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [res, setRes] = useState({});
  const [imageurl, setImageUrl] = useState("");
  const [flag, setFlag] = useState(false);
  const [flag1, setFlag1] = useState(false);
  const [empEmail, setEmpEmail] = useState("");
  const [refInviceNum, setRefInviceNum] = useState("");
  const [refApprovalId, setRefApprovalId] = useState("");
  const [expDetails, setExpDetails] = useState({});
  const [gemini, setGemini] = useState({});

  const navigate = useNavigate();
  useEffect(() => {
    if (Object.keys(res).length > 0) {
      setFlag(true);
    //   setFlag1(true);
    } else {
      setFlag(false);
    //   setFlag1(false);
    }
  }, [res]);


  const details1 = details?.row?.invoice_number;

  useEffect(() => {
   
    const email = details?.row?.email;
    const expenseDetails = details?.row;
    const refInv = details?.row?.invoiceNumber;
    const refAppID = details?.row?.approvalID;
  // const  det=details1.json()
    setGemini(details1)
    setEmpEmail(email);
    setImageUrl(details1);
    setExpDetails(expenseDetails);
    setRefApprovalId(refAppID);
    setRefInviceNum(refInv);
 

    
   
  }, []);


  const details2 = JSON.parse(details1)
console.log("det", details2);
  const AddBill = () => {
    try {
      const apidata = addDoc(
        collection(db, "ocrdatbaseitems"),
        res,
        // {
        //   // email: empEmail,
        //   // refInvoiceNumber: refInviceNum,
        //   // refApprovalID: refApprovalId,
        //   // userVerified: "Pending",
        //   ...res,
        // }
      )
        .then((res) => res)
        .then((red) => {
          navigate("/aaa");
          console.log(red);
        })

        .catch((err) => console.log("err", err));
    } catch (err) {
      console.log("err");
    }
  };

  const handleonclick = async () => {
    setLoading(true);
    window.open(imageurl, "_blank", "width=600,height=400");
    console.log("details JSON:", imageurl);
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${API_Key}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,OPTIONS",
          },

          body: JSON.stringify({
            model: "gpt-4-vision-preview",

            messages: [
            
              {
                role: "user",
                content: [
                  {
                    type: "text",
                    text:"Build an AI to convert invoice images to JSON. Key data includes 'invoice_number', 'invoice_date', 'total_amount', and 'vendor_name', all string-formatted. Ensure JSON output strictly contains these elements.",
                    //"Extract structured data from an invoice image into JSON with fields: vendor details (name, address, GSTIN), customer details (name, address, GSTIN), invoice metadata (number, date, P.O. number, P.O. date), itemization (description, HSN/SAC code, quantity, rate, unit, GST, amount), totals (subtotal, taxes, total amount), additional data (amount in words, bank details, terms, signatory), all as strings", 
                    //"Develop an AI model to transform visual invoice data into structured JSON text. The primary fields for extraction include 'invoice_number', capturing the unique identifier of the invoice; 'invoice_date', detailing when the invoice was issued; 'total_amount', representing the final amount and 'vendor_name' only show not any extra comees in response and all value should be in string",
                    //"Develop an AI model to transform visual invoice data into structured JSON text. This model must accurately extract and categorize critical invoice elements with high precision. The primary fields for extraction include 'invoice_number', capturing the unique identifier of the invoice; 'invoice_date', detailing when the invoice was issued; 'total_amount', representing the final amount due after all calculations; 'items', listing the products or services provided; 'quantity', noting the amount of each item; 'description', providing detailed explanations of each item; and 'vendor_name', identifying the seller. The output must strictly adhere to JSON format, focusing solely on these key invoice components without including any extraneous text or data. and in items rate pice amount and unit price All data comes in 'unit_price' and in items item, name , item name add data comes in 'item', and all data come in string formate only.",
                    //"Develop an AI model to convert invoice images into JSON, extracting 'invoice_number', 'invoice_date', 'total_amount', 'vendor_name', and 'items' with 'item_name', 'quantity', 'unit_price', 'description', ensuring all data is in string format and focusing only on these key elements without extra data.",
                    //"Develop an AI model to transform visual invoice data into structured JSON text. This model must accurately extract and categorize critical invoice elements with high precision. The primary fields for extraction include 'invoice_number', capturing the unique identifier of the invoice; 'invoice_date', detailing when the invoice was issued; 'total_amount', representing the final amount due after all calculations; 'vendor_name', identifying the seller; and 'items', a list encompassing each provided product or service with sub-fields 'item_name' (the name of the item), 'quantity' (the amount of each item), 'unit_price' (the price per unit), and 'description' (detailed explanations of each item). The output must strictly adhere to JSON format, focusing solely on these key invoice components, ensuring that all data, including 'item_name', 'quantity', 'unit_price', and 'description' within 'items', as well as 'invoice_number', 'invoice_date', 'total_amount', and 'vendor_name', are presented in string format exclusively, without including any extraneous text or data.",
                    //"Develop an AI model to transform visual invoice data into structured JSON text. This model must accurately extract and categorize critical invoice elements with high precision. The primary fields for extraction include 'invoice_number', capturing the unique identifier of the invoice; 'invoice_date', detailing when the invoice was issued; 'total_amount', representing the final amount due after all calculations; 'items', listing the products or services provided; 'quantity', noting the amount of each item; 'description', providing detailed explanations of each item; and 'vendor_name', identifying the seller. The output must strictly adhere to JSON format, focusing solely on these key invoice components without including any extraneous text or data. and in items rate pice amount and unit price All data comes in 'unit_price' and in items item, name , item name add data comes in 'item', and all data come in string formate only.",

                    //"Convert image data to text in JSON format no other extra text allowed main fields are same as invoice_number, invoice_date, total_amout, items, quentity, discription, vendor_name",
                    //"extract data from this bill/invoice image and pdf file and give response details in JSON format and new line /n  in corresponding format, ‘invoice_number’, 'invoice_date', 'total_amount', vendor_name, and item list is given so fetch data description, 'quantity' and ‘unit_price’  Format don't have the titles of the categories just output the requested values.",

                    // text: "Develop an AI model to transform visual invoice data into structured JSON text. This model must accurately extract and categorize critical invoice elements with high precision. The primary fields for extraction include 'invoice_number', capturing the unique identifier of the invoice; 'invoice_date', detailing when the invoice was issued; 'total_amount', representing the final amount due after all calculations; 'items', listing the products or services provided; 'quantity', noting the amount of each item; 'description', providing detailed explanations of each item; and 'vendor_name', identifying the seller. The output must strictly adhere to JSON format, focusing solely on these key invoice components without including any extraneous text or data."
                  },

                  {
                    type: "image_url",
                    image_url: {
                      url: "https://firebasestorage.googleapis.com/v0/b/wellserved01.appspot.com/o/users%2FfQ4Wu6eYpKQUQ7L8ADzsZyFXcrz1%2Fuploads%2F1713777859705000.jpg?alt=media&token=2dfeead9-7526-40cd-b647-389117c3a47a",
                      //imageurl,
                    //  url:"https://drive.google.com/file/d/1mIQd-S1poPS8F6hYCl9MmDHsmpl46w6F/view",
                       // url:"https://ibb.co/JdNHQk8",
                      //"https://firebasestorage.googleapis.com/v0/b/procure-to-pay-9604a.appspot.com/o/users%2FUeLc4gub9sfaJqqbhk0G65DSTKv2%2Fuploads%2F1712494161446756.pdf?alt=media&token=02f199f1-75d0-4f9d-b1c7-2473f8cb8baa",
                      //"https://firebasestorage.googleapis.com/v0/b/procure-to-pay-9604a.appspot.com/o/users%2FUeLc4gub9sfaJqqbhk0G65DSTKv2%2Fuploads%2F1712318804149326.pdf?alt=media&token=82e188d0-0a6e-41cd-96d5-7e7b6af75ac0",
                      //  url:"https://1.bp.blogspot.com/-9lR-V7pCE1o/X6uQCmRhrwI/AAAAAAACYUo/1Jf5PkooINsd1DclNWa4ZXHNIqfy40V6QCLcBGAsYHQ/s16000/100-free-invoice-templates-print-email-invoices.png"
                      // url:"https://i.pinimg.com/originals/7f/d5/f6/7fd5f6b8bfcca3b465098e7bb6d532fd.jpg"
                      // url:"https://images.examples.com/wp-content/uploads/2018/06/Free-Sales-Invoice-Example.jpg"
                      // url: "https://pbs.twimg.com/media/C2xuqnWWEAAqRqF.jpg",
                      // "url": "https://media-cdn.tripadvisor.com/media/photo-s/12/ca/02/c9/bill.jpg"
                      // url: "https://www.zoho.com/invoice/images/invoice-templates/excel-invoice-template/excel-invoice-template-2x.jpg",
                     
                      //url:"https://firebasestorage.googleapis.com/v0/b/procure-to-pay-9604a.appspot.com/o/users%2FUeLc4gub9sfaJqqbhk0G65DSTKv2%2Fuploads%2F1712319343880562.jpg?alt=media&token=0dfdc869-fbf8-4ad8-ba11-571324bf9ec4"
                    },
                  },
                ],
              },
            ],
            max_tokens: 1500,
          }),
        }
      );

      const data = response; // Assign on verable and save the response in that veriable
      //.choices[0].message.content;

      // if(!data){
      //   data =undefined;

      //   // const data1 = await data?.json();
      //   // const data2 = data1?.choices?.[0]?.message?.content;
      //   // console.log("helpp ",data2);
      // }
// console.log("helpp proper res ",data)
      const data1 = await data?.json(); // Convert Jata in JSON Function

      const data2 = data1?.choices?.[0]?.message?.content; //Assign one Veriable and reach Endpoint of our response(Data)

      //const data3 = data2.toString().split("```json")[1]?.split("```")[0]; // use Split function for Slipt String Value and save Split data in data3 veriable

      // const data4 = data3 ? JSON.parse(data3):""; // After Spliting data comes in String Formate so use JSON.parse() function to convert tring data in to JSON formate
      if (data2) {
        const data3 = data2.toString().split("```json")?.[1]?.split("```")?.[0];
        const data4 =
          data3 && Object.keys(data3).length ? JSON.parse(data3) : undefined;
       // setRes(data4);
         console.log("data1234",data4);
        console.log("AI result", data4);
        if (Object.keys(data4).length) {
          //  setLoading(true);
        }
      }
     // console.log("data", res);
    } catch (error) {
      console.log(error);
      // handleonclick();
    } finally {
      setLoading(false);
    // Set loading to false regardless of success or failure
    }
  };

  const handleonclickItem = async () => {
    setLoading1(false);
    // setLoading(false);
    // setFlag1(true);
   // window.open(imageurl, "_blank", "width=600,height=400");
   // console.log("details JSON:", imageurl);
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${API_Key}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,OPTIONS",
          },

          body: JSON.stringify({

            model: "gpt-3.5-turbo-0125",
            response_format: { "type": "json_object" },
            // model: "gpt-3.5-turbo",
            //"gpt-4-vision-preview",

            messages: [
              {
                role: "system",
                content:
                  "You are a helpful assistant invoice bill  to convert in JSON Type",
              },
              {
                role: "user",
                content: [
                  {
                    type: "text",
                    text: details1,
                    //"Develop an AI model to convert invoice images into JSON, extracting 'invoice_number', 'invoice_date', 'total_amount', 'vendor_name', and 'items' with 'item_name', 'quantity', 'unit_price', 'description', ensuring all data is in string format and focusing only on these key elements without extra data.",
                    //"Develop an AI model to transform visual invoice data into structured JSON text. This model must accurately extract and categorize critical invoice elements with high precision. The primary fields for extraction include 'invoice_number', capturing the unique identifier of the invoice; 'invoice_date', detailing when the invoice was issued; 'total_amount', representing the final amount due after all calculations; 'vendor_name', identifying the seller; and 'items', a list encompassing each provided product or service with sub-fields 'item_name' (the name of the item), 'quantity' (the amount of each item), 'unit_price' (the price per unit), and 'description' (detailed explanations of each item). The output must strictly adhere to JSON format, focusing solely on these key invoice components, ensuring that all data, including 'item_name', 'quantity', 'unit_price', and 'description' within 'items', as well as 'invoice_number', 'invoice_date', 'total_amount', and 'vendor_name', are presented in string format exclusively, without including any extraneous text or data.",
                    //"Develop an AI model to transform visual invoice data into structured JSON text. This model must accurately extract and categorize critical invoice elements with high precision. The primary fields for extraction include 'invoice_number', capturing the unique identifier of the invoice; 'invoice_date', detailing when the invoice was issued; 'total_amount', representing the final amount due after all calculations; 'items', listing the products or services provided; 'quantity', noting the amount of each item; 'description', providing detailed explanations of each item; and 'vendor_name', identifying the seller. The output must strictly adhere to JSON format, focusing solely on these key invoice components without including any extraneous text or data. and in items rate pice amount and unit price All data comes in 'unit_price' and in items item, name , item name add data comes in 'item', and all data come in string formate only.",

                    //"Convert image data to text in JSON format no other extra text allowed main fields are same as invoice_number, invoice_date, total_amout, items, quentity, discription, vendor_name",
                    //"extract data from this bill/invoice image and pdf file and give response details in JSON format and new line /n  in corresponding format, ‘invoice_number’, 'invoice_date', 'total_amount', vendor_name, and item list is given so fetch data description, 'quantity' and ‘unit_price’  Format don't have the titles of the categories just output the requested values.",

                    // text: "Develop an AI model to transform visual invoice data into structured JSON text. This model must accurately extract and categorize critical invoice elements with high precision. The primary fields for extraction include 'invoice_number', capturing the unique identifier of the invoice; 'invoice_date', detailing when the invoice was issued; 'total_amount', representing the final amount due after all calculations; 'items', listing the products or services provided; 'quantity', noting the amount of each item; 'description', providing detailed explanations of each item; and 'vendor_name', identifying the seller. The output must strictly adhere to JSON format, focusing solely on these key invoice components without including any extraneous text or data."
                  },

                  // {
                  //   type: "image_url",
                  //   image_url: {
                  //       url:"https://drive.google.com/file/d/1mIQd-S1poPS8F6hYCl9MmDHsmpl46w6F/view",
                  //     //"https://firebasestorage.googleapis.com/v0/b/procure-to-pay-9604a.appspot.com/o/users%2FUeLc4gub9sfaJqqbhk0G65DSTKv2%2Fuploads%2F1712494161446756.pdf?alt=media&token=02f199f1-75d0-4f9d-b1c7-2473f8cb8baa",
                  //     //"https://firebasestorage.googleapis.com/v0/b/procure-to-pay-9604a.appspot.com/o/users%2FUeLc4gub9sfaJqqbhk0G65DSTKv2%2Fuploads%2F1712318804149326.pdf?alt=media&token=82e188d0-0a6e-41cd-96d5-7e7b6af75ac0",
                  //     //  url:"https://1.bp.blogspot.com/-9lR-V7pCE1o/X6uQCmRhrwI/AAAAAAACYUo/1Jf5PkooINsd1DclNWa4ZXHNIqfy40V6QCLcBGAsYHQ/s16000/100-free-invoice-templates-print-email-invoices.png"
                  //     // url:"https://i.pinimg.com/originals/7f/d5/f6/7fd5f6b8bfcca3b465098e7bb6d532fd.jpg"
                  //     // url:"https://images.examples.com/wp-content/uploads/2018/06/Free-Sales-Invoice-Example.jpg"
                  //     // url: "https://pbs.twimg.com/media/C2xuqnWWEAAqRqF.jpg",
                  //     // "url": "https://media-cdn.tripadvisor.com/media/photo-s/12/ca/02/c9/bill.jpg"
                  //     // url: "https://www.zoho.com/invoice/images/invoice-templates/excel-invoice-template/excel-invoice-template-2x.jpg",
                  //    // url: imageurl,
                  //     //url:"https://firebasestorage.googleapis.com/v0/b/procure-to-pay-9604a.appspot.com/o/users%2FUeLc4gub9sfaJqqbhk0G65DSTKv2%2Fuploads%2F1712319343880562.jpg?alt=media&token=0dfdc869-fbf8-4ad8-ba11-571324bf9ec4"
                  //   },
                  // },
                ],
              },
            ],
            max_tokens: 300,
          }),
        }
      );

      const data = response; // Assign on verable and save the response in that veriable
      //.choices[0].message.content;

      // if(!data){
      //   data =undefined;

      //   // const data1 = await data?.json();
      //   // const data2 = data1?.choices?.[0]?.message?.content;
      //   // console.log("helpp ",data2);
      // }

      const data1 = await data?.json(); // Convert Jata in JSON Function

      console.log("helpp ",data1);

      const data2 = data1?.choices?.[0]?.message?.content; //Assign one Veriable and reach Endpoint of our response(Data)

      //const data3 = data2.toString().split("```json")[1]?.split("```")[0]; // use Split function for Slipt String Value and save Split data in data3 veriable

      // const data4 = data3 ? JSON.parse(data3):""; // After Spliting data comes in String Formate so use JSON.parse() function to convert tring data in to JSON formate
      if (data2) {
        const data3 = data2.toString().split("```json")?.[1]?.split("```")?.[0];
        const data4 =
          data3 && Object.keys(data3).length ? JSON.parse(data3) : undefined;
        setRes(data4);
        console.log("AI result items", data4);
        if (Object.keys(data4).length) {
           setLoading(true);
        }
      }
      console.log("data", res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false); // Set loading to false regardless of success or failure
    }
  };

  const handleChange = (e, index = -1) => {
    console.log("evalue", e.target.name, e.target.value, index);
    const value = e.target.value;
    const key = e.target.name;
    if (index == -1) {
      details2[key] = value;
    } else {
      details2.items[index][key] = value;
      console.log("updateitem new");
    }
    setRes(details2);
    console.log("response new", res);
  };



  return (
    <>
      <Navbaar />
      <Box mt={2} p={2}>
        {/* <Stack sx={{ width: '100%' }} spacing={2}>
     {alert? <Alert severity="success">Data Saved Successfully.</Alert>:""}
      </Stack> */}
        {/* <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              Employee Email- {empEmail}
            </Typography>
            <Typography variant="h5" component="div">
              Employee Name- {expDetails?.name}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              brand = {expDetails?.brand}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              brand = {expDetails?.subBrand}
            </Typography>
            <Typography variant="body2">
              Remarks - {expDetails?.remarks}
              <br />
            </Typography>
          </CardContent>
        </Card> */}

        <Box mt={4}>
          <Button
            variant="contained"
            color="warning"
            sx={{}}
            onClick={handleonclick}
          >
            AI Bill Fetching
          </Button>
        </Box>
        {/* use loging  For hide Data and show loading  */}
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={4}
          >
            <CircularProgress color="warning" />
          </Box>
        ) : (
          <Box>
            <Box mt={5}>
              {/* use flag For hide Data and sjow loading logic */}
              {!flag ? (
                <div>
                  <Box mt={5}>
                    Total Amount-
                    <input
                      style={{ fontSize: "18px" }}
                      defaultValue={details2.total_amount}
                      name="total_amount"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </Box>

                  <Box mt={5}>
                    Invoice Number -
                    <input
                      style={{ fontSize: "18px" }}
                      defaultValue={details2.invoice_number}
                      name="invoice_number"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </Box>
                  <Box mt={5}>
                    Bill Invoice date -
                    <input
                      type="text"
                      style={{ fontSize: "18px" }}
                      defaultValue={details2.invoice_date}
                      name="invoice_date"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </Box>

                  <Box mt={5}>
                    Vendor Name -
                    <input
                      style={{ fontSize: "18px" }}
                      defaultValue={details2.vendor_name}
                      name="vendor_name"
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </Box>

                  <Box mt={4}>
                    <Button
                      variant="contained"
                      color="warning"
                      sx={{}}
                      onClick={handleonclickItem}
                    >
                      AI Itemization
                    </Button>
                  </Box>

                

                      {/* For Connect with firebase and Save Response Button Code Start here */}

                   
                  {/* For Connect with firebase and Save Response Button Code End here */}
                </div>
              ) : (
                "Please Click to Fetch All Data From AI "
              )}
            </Box>
          </Box>
        )}
      </Box>


      {loading&&loading1 ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt={4}
                    >
                      <CircularProgress color="warning" />
                    </Box>
                  ) : (
                    <Box>
                    

        {!flag1 ? (
                        <Box>
                          {/* Data Item Wise Code Mapping Start Here */}

                          {details2?.items &&
                            details2.items.map((el, index) => (
                              <div>
                                <Box sx={{ display: "flex" }}>
                                  <Box mt={5}>
                                    Item Name -
                                    <input
                                      type="text"
                                      defaultValue={el.item_name}
                                      name="description"
                                      onChange={(event) => {
                                        handleChange(event, index);
                                      }}
                                    />
                                  </Box>
                                  <Box mt={5} ml={4}>
                                    Item Description-
                                    <input
                                      type="text"
                                      defaultValue={el.description}
                                      name="rate"
                                      onChange={(event) => {
                                        handleChange(event, index);
                                      }}
                                    />
                                  </Box>

                                  <Box mt={5} ml={4}>
                                    Item QTY -
                                    <input
                                      type="text"
                                      defaultValue={el.quantity}
                                      name="quantity"
                                      onChange={(event) => {
                                        handleChange(event, index);
                                      }}
                                    />
                                  </Box>
                                  <Box mt={5} ml={4}>
                                    Unit Price -
                                    <input
                                      type="text"
                                      defaultValue={el.unit_price}
                                      name="rate"
                                      onChange={(event) => {
                                        handleChange(event, index);
                                      }}
                                    />
                                  </Box>


                               
                                </Box>

                                {/* Data Item Wise Code Mapping End Here */}

                                {/* <Box mt={4}>
                        <Button
                          variant="contained"
                          color="warning"
                          sx={{}}
                          onClick={AddBill}
                        >
                          Initiate Approval
                        </Button>
                      </Box> */}
                              </div>
                              
                            ))}

<Box mt={4}>
                        <Button
                          variant="contained"
                          color="warning"
                          sx={{}}
                          onClick={AddBill}
                        >
                          Initiate Approval
                        </Button>
                      </Box>
                        </Box>
                      ) : (
                        "Please Click to Fetch All Item Wise data "
                      )}
                        
                        
                    </Box>
                  )}




    </>
  );
}

export default ItemOcr;
