import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import OcrToFirebase from "./Components/OcrToFirebase";
import Login from "./Components/Login/Login";
import ApprovalUserDataTable from "./Components/ApprovalUserDataTable";
import ExpenseDetails from "./Components/ExpenseDetails";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "./firebase";
import ItemOcr from "./Components/ItemOcr";
import { Table } from "@mui/material";
import TableExp from "./Components/TableExp";
import ItemizedTable from "./Components/ItemizedTable";
import Gemini from "./Gemini";
import GemeiniToOCR from "./Components/GemeiniToOCR";
import HawkApprovalDatabasetable from "./Components/HawkApprovalDatabasetable";
import Dashboard from "./Components/Dashboard";
import Users from "./Components/Users";
import Settings from "@mui/icons-material/Settings";
import ApprovalVSExpense from "./Components/ApprovalVSExpense";
import RejectedExpense from "./Components/RejectedExpense";
import SalesforceExpenseDatatable from "./Components/SalesforceExpenseDatatable";
import FilterExp from "./Components/FilterExp";
import SalesforceExpPendingandRejected from "./Components/SalesforceExpPendingandRejected";
import SalesforceExpVsApp from "./Components/SalesforceExpVsApp";

const auth = getAuth(app);

function App() {
  const [details, setDetails] = useState({});
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("signIn hoo bhai tm");
        setUser(user);
      } else {
        console.log("logout");
        setUser(null);
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/asd" element={<OcrToFirebase details={details}/>}/> */}
        {user === null ? (
          <Route path="/" element={<Login />} />
        ) : (
          <Route path="/asd" element={<OcrToFirebase details={details} />} />
        )}
        {user === null ? (
          <Route path="/" element={<Login />} />
        ) : (
          <Route
            path="/tab"
            element={<ApprovalUserDataTable setDetails={setDetails} />}
          />
        )}
        {/* <Route path="/tab"  element={<ApprovalUserDataTable setDetails={setDetails}/>}/> */}
        {(!user) ? (
          
          <Route path="/" element={<Login />} />
        ) : (
          <Route path="/det" element={<ExpenseDetails />} />
        )}
        {/* <Route path="/det" element={<ExpenseDetails />}/> */}

        <Route
            path="/invoice"
            element={<ItemOcr details={details}/>}
          />

<Route
            path="/tab1"
            element={<TableExp setDetails={setDetails} />}
          />
          <Route
            path="/itemtable"
            element={<ItemizedTable  />}
          />

<Route
            path="/aaa"
            element={<Gemini setDetails={setDetails} />}
          />


<Route
            path="/sss"
            element={<GemeiniToOCR details={details}/>}
          />

<Route
            path="/approvaltable"
            element={<HawkApprovalDatabasetable details={details}/>}
          />

<Route path="/das" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="/settings" element={<Settings />} />

        <Route path="/appvsexp" element={<ApprovalVSExpense />} />
        <Route path="/reject" element={<RejectedExpense />} />
        <Route path="/salesforce" element={<SalesforceExpenseDatatable />} />
        <Route path="/salesforceprocess" element={<SalesforceExpPendingandRejected />} />
        <Route path="/salesappvsexp" element={<SalesforceExpVsApp />} />
        <Route path="/zzz" element={<FilterExp />} />

      </Routes>
      

      
    </BrowserRouter>
  );
}

export default App;
