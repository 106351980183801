import { initializeApp } from "firebase/app";


const firebaseConfig = {
    apiKey: "AIzaSyCf9HdZzG46z26qsXYYh9oUS7Fqf2gd34o",
    authDomain: "procure-to-pay-9604a.firebaseapp.com",
    projectId: "procure-to-pay-9604a",
    storageBucket: "procure-to-pay-9604a.appspot.com",
    messagingSenderId: "632406467525",
    appId: "1:632406467525:web:38754584c80d1fb4d0e1ac",
    databaseURL: "https://procure-to-pay-9604a-default-rtdb.firebaseio.com"
  };
  
  export const app = initializeApp(firebaseConfig);


  // Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration


 
