import React, { useEffect, useState } from "react";
import { Button, Box, CircularProgress } from "@mui/material";
import { getFirestore, collection, addDoc,update , documentId, CollectionReference, DocumentReference, DocumentSnapshot ,updateDoc, doc } from "firebase/firestore";
import { app } from "../firebase";
//import db from "./firebase"
import { useNavigate } from "react-router-dom";
import { onSnapshot } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Navbaar from "./Navbaar/Navbaar";

const API_Key = "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";
const db = getFirestore(app);

function GemeiniToOCR({ details }) {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [res, setRes] = useState({});
  const [imageurl, setImageUrl] = useState("");
  const [flag, setFlag] = useState(false);
  const [flag1, setFlag1] = useState(false);
  const [expapprovalID, setExpApprovalID] = useState("");
  const [refInviceNum, setRefInviceNum] = useState("");
  const [expenseInvoiceNumber, setexpenseInvoiceNumber] = useState("");
  const [expDetails, setExpDetails] = useState({});
  const [gemini, setGemini] = useState({});
  const [geminiresuniqID, setGeminiresuniqID] = useState("");
  const [gid, setGID] = useState("");
 // const [docid, setDocID]=useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (Object.keys(res).length > 0) {
      setFlag(true);
      //   setFlag1(true);
    } else {
      setFlag(false);
      //   setFlag1(false);
    }
  }, [res]);

  const details1 = details?.row?.invoice_number;
  console.log("Ai response", typeof details1);
  // const d= JSON.stringify(details1);
  // const t= d.toString().split("```")[1]?.split("```")[0];
  // console.log("Ai response",t);

  useEffect(() => {
    const ApprovalID = details?.row?.approvalID;
    const expenseDetails = details?.row;
    const expInvoiceNumber = details?.row?.expenseInvoiceNo;
    const refAppID = details?.row?.approvalID;
    const gemresuniqueID = details?.row?.uniqID;
    const fileURL = details?.row?.pdfUrl;
    const GemID = details?.row?.id;

    // const  det=details1.json()
    setGemini(details1);
    setExpApprovalID(ApprovalID);
    setImageUrl(fileURL);
    setExpDetails(expenseDetails);
    setexpenseInvoiceNumber(expInvoiceNumber);
    setGeminiresuniqID(gemresuniqueID);
   setGID(GemID);

    // setRefInviceNum(refInv);
  }, []);

  //const res = JSON.parse(details1)
  console.log("det", details);
 // setDocID(details.id);


const handleonclick1= () => {
  try {
    const userDoc = doc(db, "testGemini", gid);
const newFields = { pStatus: "Rejected" };
 updateDoc(userDoc, newFields)
      .then((res) => res)
      .then((red) => {
        navigate("/aaa");
        console.log(red);
      })
      .catch((err) => console.log("err", err));
  } catch (err) {
    console.log("err");
  }
};


  const AddBill = (abc) => {
    try {
      const apidata = addDoc(
        collection(db, "geminiDataAiItems"),
        //  res,
        {
          refApprovalID: expapprovalID,
          refInvoiceNumber: expenseInvoiceNumber,
          uniqID: geminiresuniqID,
          // userVerified: "Pending",
          ...res,
        }
      )
      const userDoc = doc(db, "testGemini", gid);
  const newFields = { pStatus: "Approved" };
   updateDoc(userDoc, newFields)


        .then((res) => res)
        .then((red) => {
          navigate("/aaa");
          console.log(red);
        })

        .catch((err) => console.log("err", err));
    } catch (err) {
      console.log("err");
    }
  };

  const handleonclick = async () => {
    setLoading(true);
    window.open(imageurl, "_blank", "width=600,height=400");
     console.log("details JSON:", imageurl);
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${API_Key}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,OPTIONS",
          },

          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            //"gpt-4-vision-preview",
            // type: "json_object",

            messages: [
              {
                role: "system",
                content:
                  "You are a helpful assistant invoice bill to convert in JSON Parse all values should be in string",
              },

              {
                role: "user",
                content: [
                  {
                    type: "text",
                    text: details1,
                  },
                ],
              },
            ],
            max_tokens: 1500,
          }),
        }
      );

      const data = response; // Assign on verable and save the response in that veriable
      //.choices[0].message.content;

      // if(!data){
      //   data =undefined;

      //   // const data1 = await data?.json();
      //   // const data2 = data1?.choices?.[0]?.message?.content;
      //   // console.log("helpp ",data2);
      // }
      // console.log("helpp proper res ",data)
      const data1 = await data?.json(); // Convert Jata in JSON Function

      console.log("data1", data1);

      const data2 = data1?.choices?.[0]?.message?.content; //Assign one Veriable and reach Endpoint of our response(Data)

      //const data3 = data2.toString().split("```json")[1]?.split("```")[0]; // use Split function for Slipt String Value and save Split data in data3 veriable
      console.log("AI result Data 2", data2);
      const abc = JSON.parse(data2);
      console.log("AI result Data 2 abcd", abc);
      setRes(abc);
      // const data4 = data3 ? JSON.parse(data3):""; // After Spliting data comes in String Formate so use JSON.parse() function to convert tring data in to JSON formate
      if (data2) {
        const data3 = data2.toString().split("```json")?.[1]?.split("```")?.[0];
        const data4 =
          data3 && Object.keys(data3).length ? JSON.parse(data3) : undefined;
        //  setRes(abc);
        //  console.log("data1234",data4);
        console.log("AI result", data4);
        if (Object.keys(data4).length) {
          //  setLoading(true);
        }
      }
      // console.log("data", res);
    } catch (error) {
      console.log(error);
      // handleonclick();
    } finally {
      setLoading(false);

      // Set loading to false regardless of success or failure
    }
  };

  console.log("res", res);

  const handleChange = (e, index = -1) => {
    console.log("evalue", e.target.name, e.target.value, index);
    const value = e.target.value;
    const key = e.target.name;
    if (index == -1) {
      res[key] = value;
    } else {
      res.items[index][key] = value;
      console.log("updateitem new");
    }
    setRes(res);
    console.log("response new", res);
  };
  const handelclick = () => {
    navigate("/itemtable");
  };

  const handelclick1 = () => {
    navigate("/aaa");
  };
  const handelclick2 = () => {
    navigate("/tab");
  };
  const handelclick3 = () => {
    navigate("/approvaltable");
  };

  return (
    <>
      <Box sx={{ marginBottom: "5px" }}>
        <Navbaar />
        {/* <Button variant="text" color="warning" onClick={updateUser}>
          Save update
        </Button> */}
      </Box>

      <Box
        mb={0.5}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "10px",
        }}
      >
        <Box
          sx={{
            color: "orange",
            fontSize: "22px",
            marginLeft: "10px",
            marginTop: "5px",
          }}
        >
          Invoice Number - {expenseInvoiceNumber}
        </Box>
        <Box sx={{ textAlign: "right", marginRight: "10px" }}>
        <Button variant="text" color="warning" onClick={handelclick3}>
          Approval Master Table
        </Button>
          <Button variant="text" color="warning" onClick={handelclick2}>
            Expense Table
          </Button>
          <Button variant="text" color="warning" onClick={handelclick}>
            Item Table
          </Button>
          <Button variant="text" color="warning" onClick={handelclick1}>
            AI Item Table List
          </Button>
        </Box>
      </Box>
      <Box mt={4} sx={{display:"flex", justifyContent:"space-between"}}>
        <Button
          variant="contained"
          color="warning"
          sx={{}}
          onClick={handleonclick}
        >
          AI Bill Fetching
        </Button>
        <Button
          variant="contained"
          color="warning"
          sx={{marginRight:"20px"}}
          onClick={handleonclick1}
        >
          Reject Fetching
        </Button>
      </Box>
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
          <CircularProgress color="warning" />
        </Box>
      ) : (
        <Box>
          <Box mt={5}>
            {/* use flag For hide Data and sjow loading logic */}
            {flag ? (
              <div>
                <Box mt={5}>
                  Total Amount-
                  <input
                    style={{ fontSize: "18px" }}
                    defaultValue={res.total_amount}
                    name="total_amount"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </Box>

                <Box mt={5}>
                  Invoice Number -
                  <input
                    style={{ fontSize: "18px" }}
                    defaultValue={res.invoice_number}
                    name="invoice_number"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </Box>
                <Box mt={5}>
                  Bill Invoice date -
                  <input
                    type="text"
                    style={{ fontSize: "18px" }}
                    defaultValue={res.invoice_date}
                    name="invoice_date"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </Box>

                <Box mt={5}>
                  Vendor Name -
                  <input
                    style={{ fontSize: "18px" }}
                    defaultValue={res.vendor_name}
                    name="vendor_name"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </Box>

                {/* <Box mt={4}>
                    <Button
                      variant="contained"
                      color="warning"
                      sx={{}}
                     // onClick={handleonclickItem}
                    >
                      AI Itemization
                    </Button>
                  </Box> */}

                {/* For Connect with firebase and Save Response Button Code Start here */}
                {/* For Connect with firebase and Save Response Button Code End here */}
              </div>
            ) : (
              "Please Click to Fetch All Data From AI "
            )}
          </Box>
        </Box>
      )}

      {loading && loading1 ? (
        <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
          <CircularProgress color="warning" />
        </Box>
      ) : (
        <Box>
          {flag ? (
            <Box>
              {/* Data Item Wise Code Mapping Start Here */}

              {res?.items &&
                res.items.map((el, index) => (
                  <div>
                    <Box sx={{ display: "flex" }}>
                      <Box mt={5} ml={1}>
                        Name -
                        <input
                          type="text"
                          defaultValue={el.item_name}
                          name="name"
                          onChange={(event) => {
                            handleChange(event, index);
                          }}
                        />
                      </Box>
                      <Box mt={5} ml={4}>
                      Specification-
                        <input
                          type="text"
                          defaultValue={el.description}
                          name="Specification"
                          onChange={(event) => {
                            handleChange(event, index);
                          }}
                        />
                      </Box>
                      
                      <Box mt={5} ml={4}>
                      HSN/SAC -
                        <input
                          type="text"
                          defaultValue={el.hsn_sac}
                          name=" HSN/SAC"
                          onChange={(event) => {
                            handleChange(event, index);
                          }}
                        />
                      </Box>

                      <Box mt={5} ml={4}>
                        QTY -
                        <input
                          type="text"
                          defaultValue={el.quantity}
                          name="quantity"
                          onChange={(event) => {
                            handleChange(event, index);
                          }}
                        />
                      </Box>
                      <Box mt={5} ml={4}>
                        Unit Price -
                        <input
                          type="text"
                          defaultValue={el.unit_price}
                          name="rate"
                          onChange={(event) => {
                            handleChange(event, index);
                          }}
                        />
                      </Box>

                      <Box mt={5} ml={4}>
                        Amount -
                        <input
                          type="text"
                          defaultValue={el.amount}
                          name="rate"
                          onChange={(event) => {
                            handleChange(event, index);
                          }}
                        />
                      </Box>
                    </Box>

                    {/* Data Item Wise Code Mapping End Here */}

                    {/* <Box mt={4}>
                      <Button
                        variant="contained"
                        color="warning"
                        sx={{}}
                        onClick={AddBill}
                      >
                        Initiate Approval
                      </Button>
                    </Box> */}
                  </div>
                  
                ))}

              <Box mt={4}>
                        <Button
                          variant="contained"
                          color="warning"
                          sx={{}}
                          onClick={AddBill}
                        >
                          Save AI Response in Database 
                        </Button>
                      </Box>
            </Box>
          ) : (
            ""
          )}
        </Box>
      )}
    </>
  );
}

export default GemeiniToOCR;
