import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { orange } from '@mui/material/colors';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
function ComponentCard(props) {
    const {  HeadingValue, CardDepartment,CardDetails } = props;
  return (
    <div>


<Card  sx={{  width:360, height:163}}>
      <CardContent sx={{display:"flex", flexDirection:"column",justifyContent:"center", rowGap: "10px" }}>
     {/* < CurrencyRupeeIcon/> */}
     
        <Typography variant="h6" component="div" color={"#696969"} >
      {CardDepartment}
        </Typography>
        <Typography variant="h3" component="div" color={"#000000"}>
     {HeadingValue}
        </Typography>
        <Typography variant="body" component="div" color={"#696969"}sx={{textAlign:"end"}} >
      {CardDetails}
        </Typography>
      </CardContent>
    </Card>  
    </div>
  )
}

export default ComponentCard