// import React, { useState, useEffect } from "react";
// import { Chart } from "react-google-charts";
// import { app } from "../firebase";
// import { getFirestore, collection, addDoc, getDocs,query,where,orderBy } from "firebase/firestore";
// import Navbaar from "./Navbaar/Navbaar";
// const db = getFirestore(app);


// const data = [
//   ["Month", "Approval", "Expenses" ],
//   ["Jan", 100, 400, ],
//   ["Feb", 1170, 460],
//   ["March", 660, 1120],
//   ["Apirl", 1030, 540],
//   ["May", 1030, 540],
//   ["June", 1030, 540],
//   ["July", 1030, 540],
//   ["Aug", 1030, 540],
//   ["Sep", 1030, 540],
//   ["Oct", 1030, 540],
//   ["Nov", 1030, 540],
//   ["Dec", 1030, 540],
  
// ];
// // const options = {
// //     chart: {
// //       title: "Company Finanace Performance",
// //       subtitle: "Approval, Expenses, and Itemization",
// //     },
// //   };

//  const options = {
//   chart: {
//     title: "Company Finanace Performance",
//     subtitle: "Approval, Expenses, and Itemization",
//     colors : ['#ffbd56','#f9a541', ]
//     //"#ee8522", "#ef5f24","#ee8522"
//   },
// };


// function AppBarchart(props) {
//   const [datatable, setDatatable] = useState([]);

// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const que= query(collection(db, "expenseApprovalForm"), orderBy('timeStamp',"desc"),
//        where("approvalStatus", "==", "financeApproved"),
//       //  where("monthOfExpense", "==", "june")
//       );
//       const querySnapshot=await getDocs(que);
//       // const querySnapshot = await getDocs(
//       //   collection(db, "expenseApprovalForm")
//       // );
//       const fetchedData = querySnapshot.docs.map((doc) => doc.data());

//       const expenseMap = {};
//       fetchedData.forEach((doc) => {
//         if (expenseMap[doc.brand]) {
//           expenseMap[doc.brand] += doc.totalAmount;
//         } else {
//           expenseMap[doc.brand] = doc.totalAmount;
//         }
//       });

//       const chartData = [["Brand", "totral Expense"]];
//       for (const [brand, expense] of Object.entries(expenseMap)) {
//         chartData.push([brand, expense]);
//       }
//       setDatatable(chartData);
     


//       console.log("Data from chartbar:", chartData);
//     } catch (err) {
//       console.error("Error fetching data:", err);
//     }
//   };

//   fetchData();
// }, []);


//     //const {  data, options } = props;
//   return (
//     <div>
        


//         <Chart
//       chartType="Bar"
//       width="100%"
//       height="400px"
//       data={data}
//       options={options}
//     />   
//     </div>
//   )
// }

// export default AppBarchart


















// import React, { useState, useEffect } from "react";
// import { Chart } from "react-google-charts";
// import { app } from "../firebase";
// import { getFirestore, collection, addDoc, getDocs, query, where, orderBy } from "firebase/firestore";
// import Navbaar from "./Navbaar/Navbaar";

// const db = getFirestore(app);

// const options = {
//   chart: {
//     title: "Company Finance Performance",
//     subtitle: "Approval, Expenses, and Itemization",
//   },
//   // colors: ['#ffbd56', '#f9a541']
//   colors : ['#ffbd56','#f9a541',"#003F5C" ,"#ee8522", "#ef5f24","#ee8522" , "#4a4b4b","#011139",]
// };

// function AppBarchart(props) {
//   const [chartData, setChartData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const que = query(
//           collection(db, "expenseApprovalForm"), 
//           orderBy('timeStamp', "desc"),
//           where("approvalStatus", "==", "financeApproved")
//         );
//         const querySnapshot = await getDocs(que);
//         const fetchedData = querySnapshot.docs.map((doc) => doc.data());

//         // Process fetched data
//         const expenseMap = {};
//         fetchedData.forEach((doc) => {
//           const month = doc.monthOfExpense;  // Ensure your data contains this field
//           const brand = doc.brand;
//           const totalAmount = doc.totalAmount;

//           if (!expenseMap[month]) {
//             expenseMap[month] = {};
//           }
//           if (expenseMap[month][brand]) {
//             expenseMap[month][brand] += totalAmount;
//           } else {
//             expenseMap[month][brand] = totalAmount;
//           }
//         });

//         // Prepare data for Google Charts
//         const months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
//         const brands = [...new Set(fetchedData.map(doc => doc.brand))];
//         const chartDataArray = [["Month", ...brands]];

//         months.forEach(month => {
//           const row = [month];
//           brands.forEach(brand => {
//             row.push(expenseMap[month] && expenseMap[month][brand] ? expenseMap[month][brand] : 0);
//           });
//           chartDataArray.push(row);
//         });

//         setChartData(chartDataArray);
//       } catch (err) {
//         console.error("Error fetching data:", err);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div>
//       <Chart
//         chartType="Bar"
//         width="100%"
//         height="400px"
//         data={chartData}
//         options={options}
//       />
//     </div>
//   )
// }

// export default AppBarchart;
















import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { app } from "../firebase";
import { getFirestore, collection, getDocs, query, where, orderBy } from "firebase/firestore";
import Navbaar from "./Navbaar/Navbaar";

const db = getFirestore(app);

const options = {
  chart: {
    title: "Company Finance Performance",
    subtitle: "Approval, Expenses, and Itemization",
  },
  colors: ['#ffbd56', '#f9a541']
};

function AppBarchart() {
  const [chartData, setChartData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const que = query(
          collection(db, "expenseApprovalForm"), 
          orderBy('timeStamp', "desc"),
          where("approvalStatus", "==", "financeApproved")
        );
        const querySnapshot = await getDocs(que);
        const fetchedData = querySnapshot.docs.map((doc) => doc.data());

        const expenseMap = {};
        fetchedData.forEach((doc) => {
          const month = doc.monthOfExpense;  // Ensure your data contains this field
          const brand = doc.brand;
          const totalAmount = doc.totalAmount;

          if (!expenseMap[month]) {
            expenseMap[month] = {};
          }
          if (expenseMap[month][brand]) {
            expenseMap[month][brand] += totalAmount;
          } else {
            expenseMap[month][brand] = totalAmount;
          }
        });

        const allBrands = [...new Set(fetchedData.map(doc => doc.brand))];
        setBrands(allBrands);
        setSelectedBrands(allBrands);  // Initially select all brands

        const months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
        updateChartData(expenseMap, allBrands, months);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  const updateChartData = (expenseMap, brands, months) => {
    const chartDataArray = [["Month", ...brands]];
    months.forEach(month => {
      const row = [month];
      brands.forEach(brand => {
        row.push(expenseMap[month] && expenseMap[month][brand] ? expenseMap[month][brand] : 0);
      });
      chartDataArray.push(row);
    });
    setChartData(chartDataArray);
  };

  const handleBrandChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedBrands(selectedOptions);
  };

  useEffect(() => {
    const fetchData = async () => {
      const que = query(
        collection(db, "expenseApprovalForm"),
        orderBy('timeStamp', "desc"),
        where("approvalStatus", "==", "financeApproved")
      );
      const querySnapshot = await getDocs(que);
      const fetchedData = querySnapshot.docs.map((doc) => doc.data());

      const expenseMap = {};
      fetchedData.forEach((doc) => {
        const month = doc.monthOfExpense;  // Ensure your data contains this field
        const brand = doc.brand;
        const totalAmount = doc.totalAmount;

        if (!expenseMap[month]) {
          expenseMap[month] = {};
        }
        if (expenseMap[month][brand]) {
          expenseMap[month][brand] += totalAmount;
        } else {
          expenseMap[month][brand] = totalAmount;
        }
      });

      const months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
      updateChartData(expenseMap, selectedBrands, months);
    };

    fetchData();
  }, [selectedBrands]);

  return (
    <div>
      <div>
        <label htmlFor="brand-select">Filter by Brand:</label>
        <select id="brand-select" multiple={true} value={selectedBrands} onChange={handleBrandChange}>
          {brands.map(brand => (
            <option key={brand} value={brand}>{brand}</option>
          ))}
        </select>
      </div>
      <Chart
        chartType="Bar"
        width="100%"
        height="400px"
        data={chartData}
        options={options}
      />
    </div>
  )
}

export default AppBarchart;
