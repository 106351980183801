import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  // GridColDef,
  // GridEventListener,
  // useGridApiEventHandler,
  GridToolbar,
 
 
} from "@mui/x-data-grid";
import {  useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { app } from "./firebase";

import { getFirestore, collection,  getDocs,query,orderBy } from "firebase/firestore";
import Navbaar from "./Components/Navbaar/Navbaar";
import Layout from "./Components/Layout";
const db = getFirestore(app);


function Gemini({setDetails}) {

    const [datatable, setDatatable] = useState([]);


    const handelclick = () => {
      navigate("/tab");
    };
    const handelclick1 = () => {
      navigate("/itemtable");
    };
    const handelclick2 = () => {
      navigate("/approvaltable");
    };
   

    const columns = [
        {
            field: "uniqID",
            headerName: "Uniq ID",
            width: 170,
            editable: true,
            sortable: true,
            description: "Uniq ID",
          },
        {
          field: "invoice_number",
          headerName: "AI Response",
          width: 300,
          editable: true,
          description: "AI Response",
        },
        {
          field: "approvalID",
          headerName: "Approval ID",
          width: 150,
          editable: true,
          sortable: true,
          description: "Approval ID",
          hideable: false,

        },
        {
          field: "expenseInvoiceNo",
          headerName: "Expense Invoice Number",
          width: 170,
          editable: true,
          description: "Expense Invoice Number",
        },
     
        {
          field: "time",
          headerName: "TimeStamp",
          description: "TimeStamp",
          sortable: true,
          width: 180,
          type: "action",
          renderCell: (params) => {
            const timestamp = params?.value?.seconds * 1000;
            // Convert the timestamp to a Date object
            const date = new Date(timestamp);
            // Format the date as needed
            const formattedDate = date.toLocaleDateString('en-GB');
    
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            // Format the time as needed
    
            const formattedTime = `${hours}:${minutes}:${seconds}`;
            // Render the formatted date
            return (
              <p>
                {formattedDate} {formattedTime}
              </p>
            );
          },
        },
        {
          field: "pdfUrl",
          headerName: "Show Bill PDF",
          description: "For Show Your Bill",
          sortable: false,
          width: 100,
          type: "action",
          renderCell: (field) => (
            <a
              style={{ color: "#fcb900", fontWeight: "600", cursor: "pointer" }}
              href={field.value}
              target="_blank"
            >
              {field?.value}
            </a>
          ),
        },
        {
          field: "pStatus",
          headerName: "Status",
          width: 100,
          editable: true,
          description: "Status",
        },
    ]
;  
    useEffect(() => {
const fetchData = async () => {

    try {
      // For filter i used 2 more line 
      const que= query(collection(db, "testGemini"),orderBy('time',"desc"),);
      const querySnapshot=await getDocs(que);
     // const querySnapshot = await getDocs(collection(db, "expenseApprovalForm"), where("approvalStatus", "==", "Pending"));
      const fetchedData = querySnapshot.docs.map(doc => 
        // doc.data());
        ({
          ...doc.data(),
          id: doc.id,
          ref: doc.ref.path
        }));
      setDatatable(fetchedData)
    //  const data3 =fetchedData[0]?.invoice_number;
     // const data34= JSON.parse(data34);
    // const data5 = data3.toString().split("Data from Firestore:")?.[1]?.split()?.[0];
   //   console.log("Data from Firestore:", fetchedData);
    //console.log("Data from Firestore: data 3", data5);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  
  
  
      fetchData();
    }, []);
//const l= JSON.stringify(datatable)
   console.log("datatables:", datatable);

    //const ffff= JSON.parse(l)
   // console.log("*",ffff[0])

   const handleEvent =(event)=>{
 
    setDetails(event)
    navigate("/sss")
  }
  

  let navigate = useNavigate();


  return (
    <>
     <Box sx={{ marginBottom: "5px" }}>
        <Navbaar />
        <Layout/>
      </Box>
      <Box mb={0.5} sx={{  marginTop:"-112px",display:"flex", justifyContent: "space-between", marginRight: "10px" }}>
      {/* <Box sx={{ color: "orange", fontSize:"22px", marginLeft:"10px",marginTop:"5px"}}>AI Itemization Table</Box> */}
      {/* <Box sx={{ textAlign:"right",marginRight: "10px"}}> 
      <Button variant="text" color="warning" onClick={handelclick2}>
          Approval Master Table
        </Button>
        <Button variant="text" color="warning" onClick={handelclick}>
          Expense Table
        </Button>
        <Button variant="text" color="warning" onClick={handelclick1}>
          Item Table
        </Button>
        </Box> */}
      </Box>
      <Box
        p={0.5}
        ml={30}
        sx={{
          height: 656,
          width: "83%",
          backgroundColor: "#ffffff",
          minHeight: "600px",
          maxHeight: "100%",
          "& .discuss": {
            backgroundColor: "#fff44f",
            color: "#1a3e72",
          },
          "& .accept": {
            backgroundColor: "#3CB371",
            color: "#EFEFEF",
          },
          "& .reject": {
            backgroundColor: "#DD6464",
            color: "#EFEFEF",
          },
        }}
      >
     <DataGrid
        getRowId={(row) => row.id}
          rows={datatable ||[]}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[500]}
          components={{ Toolbar: GridToolbar }}
          rowHeight={40}
           onRowClick={handleEvent}
          // initialState={{
          //   sorting: {
          //     sortModel: [
          //       {
          //         field: 'invoiceDate',
          //         sort: 'desc',
          //       },
          //     ],
          //   },
          // }}
          getCellClassName={(params) => {
            if ( params.value === "Rejected") {
              return 'reject';
            }
            if ( params.value === "Approved") {
              return 'accept';
            }
            if ( params.value === "Discuss") {
              return 'discuss';
            }
          }}
          slots={{
            toolbar: GridToolbar,
          }}
        />
        </Box>
    
    </>
  )
}

export default Gemini