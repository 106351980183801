import React from 'react';
import { Typography,Box } from '@mui/material';
import Layout from './Layout';
import Navbaar from './Navbaar/Navbaar';
import TableUser from './TableUser';

const Users = () => {
  return (
    <div>
      <Navbaar/>
      <Layout/>
      <Box sx={{marginTop:"-112px"}} >
      <TableUser/>
      </Box>
    </div>
  );
};

export default Users;