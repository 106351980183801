import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  useGridApiEventHandler,
  GridToolbar,
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { app } from "../firebase";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import Layout from './Layout';
import Navbaar from "./Navbaar/Navbaar";
const db = getFirestore(app);
function RejectedExpense() {
    const [datatable, setDatatable] = useState([]);
    const [res, setRes] = useState({});
    const [dee, setDee] = useState("");
  
    const columns = [
      {
        field: "name",
        headerName: "Employee Name",
        width: 130,
        editable: true,
        sortable: true,
        description: "Employee Name",
      },
      {
        field: "email",
        headerName: "Email",
        width: 150,
        editable: true,
        sortable: true,
        description: "Email",
      },
      {
        field: "invoiceNumber",
        headerName: "Invoice Number",
        width: 100,
        editable: true,
        sortable: true,
        description: "Invoice Number",
        hideable: false,
      },
      {
        field: "dateInString",
        headerName: "Invoice Date for Sheet",
        width: 100,
        editable: true,
        sortable: true,
        description: "Invoice Number",
        hideable: false,
      },
      {
        field: "invoiceDate",
        headerName: "Invoice Date",
        description: "Invoice Date",
        sortable: true,
        width: 100,
        type: "action",
        renderCell: (params) => {
          const timestamp = params?.value?.seconds * 1000;
          // Convert the timestamp to a Date object
          const date = new Date(timestamp);
          // Format the date as needed
          const formattedDate =  date.toLocaleDateString('en-GB');
          // Render the formatted date
          return <p> {formattedDate}</p>;
        },
      },
  
      {
        field: "invoiceType",
        headerName: "Invoice Type",
        description: "Invoice Type",
        sortable: true,
        width: 110,
      },
      {
        field: "paymentMode",
        headerName: "Payment Mode",
        width: 140,
        editable: true,
        sortable: true,
        description: "Payment Mode",
      },
      {
        field: "vendorName",
        headerName: "Vendor Name",
        width: 120,
        editable: true,
        sortable: true,
        description: "Email",
      },
      {
        field: "descriptionOfExpense",
        headerName: "Description Of Expense",
        width: 140,
        editable: true,
        description: "Description Of Expense",
      },
      {
        field: "brand",
        headerName: "Brand",
        width: 110,
        editable: true,
        sortable: true,
        description: "Brand",
      },
      {
        field: "subBrand",
        headerName: "Sub Brand",
        width: 110,
        editable: true,
        sortable: true,
        description: "Sub Brand",
      },
      {
        field: "location",
        headerName: "Location",
        width: 140,
        editable: true,
        sortable: true,
        description: "Location",
      },
      {
        field: "expenseCategory",
        headerName: "Expense Category",
        width: 140,
        editable: true,
        sortable: true,
        description: "Expense Category",
      },
      {
        field: "expenseSubCategory",
        headerName: "Expense Sub Category",
        width: 140,
        editable: true,
        sortable: true,
        description: "Expense Sub Category",
      },
  
      {
        field: "preTaxAmount",
        headerName: "Pre Tax Amount",
        width: 140,
        editable: true,
        sortable: true,
        description: "Pre Tax Amount",
      },
      {
        field: "otherCharges",
        headerName: "Other Charges Amount",
        width: 140,
        editable: true,
        sortable: true,
        description: "Other Charges Amount",
      },
      {
        field: "cgstAmount",
        headerName: "CGST",
        width: 140,
        editable: true,
        sortable: true,
        description: "CSGT",
      },
      {
        field: "sgstAmount",
        headerName: "SGST",
        width: 140,
        editable: true,
        sortable: true,
        description: "SGST ",
      },
  
      {
        field: "igstAmount",
        headerName: "IGST ",
        width: 140,
        editable: true,
        sortable: true,
        description: "ISGT",
      },
  
      {
        field: "totalAmount",
        headerName: "Gross Amount",
        width: 100,
        editable: true,
        sortable: true,
        description: "Gross Amount",
      },
      {
        field: "invoicePdf",
        headerName: "Show Bill PDF",
        description: "For Show Your Bill",
        sortable: false,
        width: 100,
        type: "action",
        renderCell: (field) => (
          <a
            style={{ color: "#fcb900", fontWeight: "600", cursor: "pointer" }}
            href={field.value}
            target="_blank"
          >
            {field?.value}
          </a>
        ),
      },
      {
        field: "invoiceImage",
        headerName: "Show Bill Image",
        description: "For Show Your Bill",
        sortable: false,
        width: 100,
        type: "action",
        renderCell: (field) => (
          <a
            style={{ color: "#fcb900", fontWeight: "600", cursor: "pointer" }}
            href={field.value}
            target="_blank"
          >
            {field?.value}
          </a>
        ),
      },
  
      {
        field: "monthOfExpense",
        headerName: "Month Of Expense",
        width: 70,
        editable: true,
        sortable: true,
        description: "Month Of Expense",
      },
  
      {
        field: "capex_opex",
        headerName: "Capex Opex",
        width: 70,
        editable: true,
        sortable: true,
        description: "Capex Opex",
      },
  
      {
        field: "approvalID",
        headerName: " approvalID",
        width: 90,
        sortable: true,
        editable: true,
        description: "Approval Id",
        hideable: false,
      },
      {
        field: "timeStamp",
        headerName: "Invoice Submit Date",
        description: "Invoice Submit Date",
        sortable: true,
        width: 150,
        type: "",
        renderCell: (params) => {
          const timestamp = params?.value?.seconds * 1000;
          // Convert the timestamp to a Date object
          const date = new Date(timestamp);
          // Format the date as needed
          const formattedDate = date.toLocaleDateString('en-GB');
  
          const hours = date.getHours();
          const minutes = date.getMinutes();
          const seconds = date.getSeconds();
          // Format the time as needed
  
          const formattedTime = `${hours}:${minutes}:${seconds}`;
          // Render the formatted date
          return (
            <p>
              {formattedDate} {formattedTime}
            </p>
          );
        },
      },
      {
        field: "timeStampInString",
        headerName: "timeStamp For sheet",
        width: 130,
        editable: true,
        sortable: true,
        description: "timeStamp For Sheet",
      },
  
      {
        field: "customerName",
        headerName: "Customer Name",
        width: 130,
        editable: true,
        sortable: true,
        description: "Customer Name",
      },
      {
        field: "typeOfExpense",
        headerName: "Type Of Expense",
        width: 130,
        editable: true,
        sortable: true,
        description: "Type Of Expense",
      },
  
      {
        field: "remarks",
        headerName: "Remarks",
        width: 130,
        editable: true,
        sortable: true,
        description: "Remarks",
      },
  
      {
        field: "invoiceDueDateString",
        headerName: "Invoice Due Date For sheet",
        width: 130,
        editable: true,
        sortable: true,
        description: "Invoice Due Date",
      },
  
      {
        field: "invoiceDueDate",
        headerName: "Invoice Due Date",
        description: "Invoice Due Date",
        sortable: true,
        width: 100,
        type: "action",
        renderCell: (params) => {
          const timestamp = params?.value?.seconds * 1000;
          // Convert the timestamp to a Date object
          const date = new Date(timestamp);
          // Format the date as needed
          const formattedDate = date.toLocaleDateString('en-GB');
          // Render the formatted date
          return <p> {formattedDate}</p>;
        },
      },
      {
        field: "finanaceExpenseUpdateStatusTime",
        headerName: "Finance Expense Update Status Time",
        description: "Finance Expense Update Status Time",
        sortable: true,
        width: 100,
  
        renderCell: (params) => {
          const timestamp = params?.value?.seconds * 1000;
          // Convert the timestamp to a Date object
          const date = new Date(timestamp);
          // Format the date as needed
          // const formattedDate = date.toISOString().split('T')[0];
          const formattedDate = date.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
          // Render the formatted date
          return <p> {formattedDate}</p>;
        },
      },
      {
        field: "finanaceExpenseUpdateStatusTimeInString",
        headerName: "Finance Expense Update Status Time for Sheet",
        width: 130,
        editable: true,
        sortable: true,
        description: "Finance Expense Update Status Time for Sheet",
      },
  
      {
        field: "approvalStatus",
        headerName: "Approval Status",
        width: 130,
        editable: true,
        sortable: true,
        description: "Status",
      },
      {
        field: "reasonOfRejection",
        headerName: "Reason Of Rejection Approved By Finance",
        width: 130,
        editable: true,
        sortable: true,
        description: "Reason Of Rejection Approved By Finance",
      },
      {
        field: "rejectedBy",
        headerName: "Finance Expense Approved By",
        width: 130,
        editable: true,
        sortable: true,
        description: "Finance Expense Update Status Time for Sheet",
      },
    ];
  
    // const time = 1713378600;
  
    // const formattedDate = new Date(dee * 1000);
  
  
    // console.log("time", formattedDate);
    const handelclick = () => {
      navigate("/itemtable");
    };
  
    const handelclick1 = () => {
      navigate("/aaa");
    };
    const handelclick2 = () => {
      navigate("/approvaltable");
    };
  
    let navigate = useNavigate();
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const que = query(
            collection(db, "expenseApprovalForm"),
            orderBy("timeStamp", "desc"),
            where("approvalStatus", "==", "Rejected")
          );
          const querySnapshot = await getDocs(que);
          const fetchedData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref.path,
          }));
          setDatatable(fetchedData);
  
          console.log("Data from Firestore:", fetchedData);
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      };
  
      fetchData();
    }, []);
    console.log("tabledata", datatable);
  
  return (

    <>
 <Box sx={{ marginBottom: "5px" }}>
        <Navbaar />
        <Layout/>
      </Box>
      <Box
        mb={0.5}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "10px",
          marginTop:"-112px"
        }}
      >
        {/* <Box
          sx={{
            color: "orange",
            fontSize: "22px",
            marginLeft: "10px",
            marginTop: "5px",
          }}
        >
          Expense Master Table
        </Box> */}
        {/* <Box sx={{ textAlign: "right", marginRight: "10px" }}>
          <Button variant="text" color="warning" onClick={handelclick2}>
            Approval Master Table
          </Button>
          <Button variant="text" color="warning" onClick={handelclick}>
            Item Table
          </Button>
          <Button variant="text" color="warning" onClick={handelclick1}>
            AI Item Table List
          </Button>
        </Box> */}
      </Box>

      <Box
        p={0.5}
        ml={30}
        sx={{
          height: 656,
          width: "83%",
          backgroundColor: "#ffffff",
          minHeight: "600px",
          maxHeight: "100%",
          "& .discuss": {
            backgroundColor: "#ffd500",
            color: "#1a3e72",
          },
          "& .accept": {
            backgroundColor: "#3CB371",
            color: "#EFEFEF",
          },
          "& .reject": {
            backgroundColor: "#ff6347",
            color: "#EFEFEF",
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row.id}
          rows={datatable ? datatable : ""}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[500]}
          an
          components={{ Toolbar: GridToolbar }}
          rowHeight={40}
          //sortingMode="server"
          //onSortModelChange={handleSortModelChange}
          //  onRowClick={handleEvent}
          // initialState={{
          //   sorting: {
          //     sortModel: [
          //       {
          //         field: 'timeStamp',
          //         sort: 'desc',
          //       },
          //     ],
          //   },
          // }}
          getCellClassName={(params) => {
            if (params.value === "Rejected") {
              return "reject";
            }
            if (params.value === "financeApproved") {
              return "accept";
            }
            if (params.value === "Pending") {
              return "discuss";
            }
          }}
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>

    </>
  )
}

export default RejectedExpense