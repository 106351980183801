import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  useGridApiEventHandler,
  GridToolbar,
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
 
 
} from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { app } from "../firebase";
import { getFirestore, collection, addDoc, getDocs, where,query,orderBy } from "firebase/firestore";
import Navbaar from "./Navbaar/Navbaar";
const db = getFirestore(app);
function TableUser(props) {
  const {  usercount } = props;

   
    const [userdatatable, setUserDatatable] = useState([]);
    const [res, setRes] = useState({});
  
  
    const columns = [
           {
      field: "photo_url",
      headerName: "Image",
      description: "Image",
      sortable: false,
      width: 120,
      type: "action",
      renderCell: (params) => {
        return <img src={params.value?params.value:"https://pinch.co.in/wp-content/uploads/2023/05/Untitled-design-5.png"} style={{width:"35px", height:"35px", borderRadius:"50%"}}/>
      },
    },
      {
        field: "display_name",
        headerName: "Employee Name",
        width: 150,
      editable: true,
        description:"Employee Name",
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
         editable: true,
        description:"Email",
      },
      {
        field: "phone_number",
        headerName: "Phone Number",
        width: 120,
        editable: true,
        description:"Phone Number",
      },
      {
        field: "designation",
        headerName: "Position",
        width: 200,
        editable: true,
        description:"Position",
      },
   
      {
        field: "employeeCode",
        headerName: "Employee Code",
        description: "Employee Code",
        sortable: true,
        width: 120,
      }, 
      {
        field: "reportingManager",
        headerName: " Reporting Manager",
        width: 110,
      //  editable: true,
        description:"Reporting Manager",
      },
      
    
      {
        field: "postReportingManager",
        headerName: "Post Reporting Manager",
        width: 120,
        // editable: true,
        description:"Post Reporting Manager",
      },
    //   {
    //     field: "photo_url",
    //     headerName: "Image",
    //     description: "Image",
    //     sortable: false,
    //     width: 100,
    //     type: "action",
    //     renderCell: (params) => {
    //       return 
    //     },
    //   },
    //   {
    //     field: 'emp_Status',
    //     headerName: 'Status of Employee',
    //     width: 220,
    //     editable: true,
    //     type: 'singleSelect',
    //     valueOptions: ['Active', 'Inactive'],
    //     value:"Active",
    //   },
    //   {
    //     field: '',
    //     type: 'actions',
    //     headerName: 'Actions',
    //     width: 100,
    //     cellClassName: 'actions',
    //     renderCell: ({ id }) => {
    //     //  const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
        
    //         return <>
    //           <GridActionsCellItem
    //             icon={<SaveIcon />}
    //             label="Save"
    //             sx={{
    //               color: 'primary.main',
    //             }}
    //           //  onClick={handleSaveClick(id)}
    //           />,
           
    //         </>
          
    //     }
    // }
    //   {
    //     field: "doj",
    //     headerName: "Date of Joining",
    //     description: "Date of Joining",
    //     sortable: false,
    //     width: 100,
    //     type: "action",
    //     renderCell: (params) => {
    //       const timestamp = params?.value?.seconds * 1000;
    //       // Convert the timestamp to a Date object
    //       const date = new Date(timestamp);
    //       // Format the date as needed
    //       const formattedDate = date?.toISOString().split("T")[0];

    //       return (
    //         <p>
    //             {formattedDate?formattedDate:""}
    //         </p>
    //       );
    //     },
    //   },
    //   {
    //     field: "dob",
    //     headerName: "Date of Birth",
    //     description: "Date of Birth",
    //     sortable: false,
    //     width: 100,
    //     type: "action",
    //     renderCell: (params) => {
    //       const timestamp = params?.value?.seconds * 1000;
    //       // Convert the timestamp to a Date object
    //       const date = new Date(timestamp);
    //       // Format the date as needed
    //       const formattedDate = date?.toISOString().split("T")[0];
  
    //       // Render the formatted date
    //       return (
    //         <p>
    //           {formattedDate?formattedDate:""}
    //         </p>
    //       );
    //     },
    //   },
 
    ];
  
  
    const handelclick =()=>{
  
      navigate("/itemtable")
    }
    

    let navigate = useNavigate();
   
  
    useEffect(() => {
  
  const fetchData = async () => {
    try {
      // For filter i used 2 more line 
      const que= query(collection(db, "user"), orderBy('email',"asc")
     // where("approvalStatus", "==", "financeApproved")
    );
      const querySnapshot=await getDocs(que);
  
  
  
     // const querySnapshot = await getDocs(collection(db, "expenseApprovalForm"), where("approvalStatus", "==", "Pending"));
      const fetchedData = querySnapshot.docs.map(doc => 
        // doc.data());
        ({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref.path
          }));
        setUserDatatable(fetchedData)
      
      
      console.log("Data from Firestore:", fetchedData.length);
    
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  
  
  
      fetchData();
    }, []);
  
  // console.log("tabledata", userdatatable);
  // console.log("tabldescriptionOfExpense",datatable[0]?.brand)
  return (
    <>
 <Box
    p={0.5}

  ml={30}
    sx={{
      height: 656,
      width: "83%",
      backgroundColor: "#ffffff",
      minHeight: "600px",
      maxHeight: "100%",
      '& .discuss': {
        backgroundColor: '#fff44f',
        color: '#1a3e72',
      },
      '& .accept': {
        backgroundColor: '#3CB371',
        color: '#EFEFEF', 
      },
      '& .reject': {
         backgroundColor: '#DD6464',
        color: '#EFEFEF',
      },

    }}
  >
    <DataGrid
      getRowId={(row) => row.id}
      rows={userdatatable?userdatatable:""}
      columns={columns}
      pageSize={100}
      rowsPerPageOptions={[500]}
      components={{ Toolbar: GridToolbar }}
      rowHeight={40}
     // onRowClick={handleEvent}
    //   initialState={{
    //     sorting: {
    //       sortModel: [
    //         {
    //           field: 'level',
    //           sort: 'desc',
    //         },
    //       ],
    //     },
    //   }}
      // getCellClassName={(params) => {
      //   if ( params.value === "Reject") {
      //     return 'reject';
      //   }
      //   if ( params.value === "Accept") {
      //     return 'accept';
      //   }
      //   if ( params.value === "Discuss") {
      //     return 'discuss';
      //   }
      // }}
      slots={{
        toolbar: GridToolbar,
      }}
    />
  </Box>


    </>
  )
}

export default TableUser