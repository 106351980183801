import React, { useState, useEffect } from "react";
import Layout from './Layout'
import AppBarchart from './AppBarchart'
import { Box } from '@mui/material'
import Navbaar from './Navbaar/Navbaar'
import { Button } from "@mui/material";
import { app } from "../firebase";
import ComponentCard from './ComponentCard'
import { startAfter } from 'firebase/firestore'
import PieChart from './PieChart'
import dayjs from 'dayjs';
import moment from "moment";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { getFirestore, collection, addDoc, getDocs,query,where,orderBy,or } from "firebase/firestore";
const db = getFirestore(app);

function Dashboard() {
  const [datatable, setDatatable] = useState([]);
  const [expenseCount, setExpenseCount] = useState(0);
  const [userCount,setUserCount] = useState(0);
  const [approvalCount,setApprovalCount] = useState(0);
  const [value, setValue] = React.useState("");
  const [value1, setValue1] = React.useState("");
  const [totalAmount, setTotalAmount] = useState(0);


// // const k =moment(value1).format("DD-MM-YYYY")
// const l =moment(value.$d).format("DD-MM-YYYY")
const l=""
console.log("Dashboard",  l )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const que= query(collection(db, "expenseApprovalForm"), 
        where("approvalStatus", "==", "financeApproved"),
        // where("brand", "==", "D2C"),
         orderBy('timeStamp',"desc"),
        );
        const querySnapshot=await getDocs(que);
        const fetchedData = querySnapshot.docs.map(doc =>
          ({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref.path
          }));
          //  doc.data());
        setDatatable(fetchedData);
        setExpenseCount(fetchedData.length)

        const total = fetchedData.reduce((acc, curr) => acc + (curr.totalAmount || 0), 0);
        setTotalAmount(total);
        console.log("Total amount Old Dta----", fetchedData)
        console.log("Total amount", total)

        const que1= query(collection(db, "user"), orderBy('email',"asc")
       );
         const querySnapshot1=await getDocs(que1);
     
         const fetchedData1 = querySnapshot1.docs.map(doc => 
           ({
               ...doc.data(),
               id: doc.id,
               ref: doc.ref.path
             }));
             setUserCount(fetchedData1.length)
        console.log("Data from Firestore:", fetchedData.length);
        const que2= query(collection(db, "approvalManagement"),
        where("approvalStatus", "==", "AllApproved"),
       );
       const querySnapshot2=await getDocs(que2);
       const fetchedData2 = querySnapshot2.docs.map((doc) => doc.data());
       setApprovalCount(fetchedData2.length)
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);



  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const que= query(collection(db, "expenseApprovalForm"),
        
    where("approvalStatus", "==", "financeApproved"),
  // where("rmApproved", "==", "Approved"),
    where("brand", "==", "D2C"),
    orderBy('timeStamp',"desc"),
        
        );
        const querySnapshot=await getDocs(que);
        const fetchedData1 = querySnapshot.docs.map(doc =>
          ({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref.path
          }));
          //  doc.data());
        console.log("Total amount NEw -------------", fetchedData1) 
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData1();
  }, []);


  return (
    <div>
    
      <Navbaar />
   
        <Layout/>
        <Box ml={35} mt={-10} sx={{display:"flex", justifyContent:"flex-start", flexWrap:'wrap',gap:2}}>
        {/* <AppBarchart/> */}
        <ComponentCard  HeadingValue={<span> ₹  {totalAmount}
        </span>} CardDepartment={"Total Amount"}
        CardDetails={"Approved By Finance"}
        />  


<ComponentCard HeadingValue={<span>  {approvalCount} </span>} CardDepartment={"Approved Approval "} CardDetails={"Approved By Nitin Sir"} />
<ComponentCard  HeadingValue={<span>  {expenseCount}</span>} CardDepartment={"Total Expense"} CardDetails={"Approved By Finance"}/>

<ComponentCard  HeadingValue={userCount} CardDepartment={ "Number Of Employees"}CardDetails={"Active Users"}/>



        </Box>

        <Box ml={35} mt={-17} sx={{display:"flex", justifyContent:'right', }}>
     
          
           {/* <Box sx={{width:"650px"}}>
           <AppBarchart />
           </Box> */}
           {/* <Box sx={{width:"550px",}}>
<PieChart/>
</Box> */}

<Box>

 

</Box>
        </Box>
     
    </div>
  )
}

export default Dashboard