import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  useGridApiEventHandler,
  GridToolbar,
} from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { app } from "../firebase";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  where,
  query,
  orderBy
} from "firebase/firestore";
import Navbaar from "./Navbaar/Navbaar";
import Layout from "./Layout";
const db = getFirestore(app);
function ItemizedTable() {
  const [itmizedDataTable, setItmizedDataTable] = useState([]);
  const [res, setRes] = useState({});
  const [invoiceImageURL, setInvoiceImageUR] = useState("");

  const columns = [
    {
      field: "dup",
      headerName: "DUP",
      width: 200,
      editable: true,
      description: "DUP",
    },
    {
      field: "timeStamp",
      headerName: "TimeStamp",
      description: "TimeStamp",
      sortable: false,
      width: 200,
      type: "action",
      renderCell: (params) => {
        const timestamp = params?.value?.seconds * 1000;
        // Convert the timestamp to a Date object
        const date = new Date(timestamp);
        // Format the date as needed
        const formattedDate = date?.toISOString().split("T")[0];

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        // Format the time as needed

        const formattedTime = `${hours}:${minutes}:${seconds}`;
        // Render the formatted date
        return (
          <p>
            {formattedDate} {formattedTime}
          </p>
        );
      },
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      width: 200,
      editable: true,
      description: "Invoice Number",
      hideable: false,
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      description: "Invoice Date",
      sortable: true,
      width: 200,
      type: "action",
      renderCell: (params) => {
        const timestamp = params?.value?.seconds * 1000;
    // Convert the timestamp to a Date object
    const date = new Date(timestamp);
    // Format the date as needed
    const formattedDate = date.toISOString().split('T')[0];
    // Render the formatted date
        return (<p> {formattedDate}</p>) 
      } 
    },
    // {
    //   field: "invoiceDate",
    //   headerName: "Invoice Date",
    //   description: "Invoice Date",
    //   sortable: false,
    //   width: 100,
    //   type: "action",
    //   renderCell: (params) => {
    //     const timestamp = params?.value?.seconds * 1000;
    //     // Convert the timestamp to a Date object
    //     const date = new Date(timestamp);
    //     // Format the date as needed
    //     const formattedDate = date?.toISOString()?.split("T")[0];
    //     // Render the formatted date
    //     return <p> {formattedDate}</p>;
    //   },
    // },
    {
      field: "invoiceType",
      headerName: "Invoice Type",
      description: "Invoice Type",
      sortable: true,
      width: 150,
    },
    {
      field: "expenseType",
      headerName: "Expense Type",
      width: 130,
      editable: true,
      description: " Expense Type",
    },
    {
      field: "vendorName",
      headerName: "Vendor Name",
      width: 120,
      editable: true,
      description: "Email",
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 110,
      editable: true,
      description: "Brand",
    },
    {
      field: "subBrand",
      headerName: "Sub Brand",
      width: 110,
      editable: true,
      description: "Sub Brand",
    },
    {
      field: "location",
      headerName: "Location",
      width: 140,
      editable: true,
      description: "Location",
    },
    {
      field: "invoiceImage",
      headerName: "Show Bill",
      description: "For Show Your Bill",
      sortable: false,
      width: 100,
      type: "action",
      renderCell: (field) => (
        <a
          style={{ color: "#fcb900", fontWeight: "600", cursor: "pointer" }}
          href={field.value}
          target="_blank"
        >
          {field?.value}
        </a>
      ),
    },
    {
      field: "invoicePdf",
      headerName: "Show Bill",
      description: "For Show Your Bill",
      sortable: false,
      width: 100,
      type: "action",
      renderCell: (field) => (
        <a
          style={{ color: "#fcb900", fontWeight: "600", cursor: "pointer" }}
          href={field.value}
          target="_blank"
        >
          {field?.value}
        </a>
      ),
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 130,
      editable: true,
      description: "Customer Name",
    },
    // {
    //   field: "typeOfExpense",
    //   headerName: "Type Of Expense",
    //   width: 130,
    //   editable: true,
    //   description: "Type Of Expense",
    // },
    {
      field: "expenseCategory",
      headerName: "Expense Category",
      width: 140,
      editable: true,
      description: "Expense Category",
    },
    {
      field: "subCategory",
      headerName: "Expense Sub Category",
      width: 140,
      editable: true,
      description: "Expense Sub Category",
    },
    {
      field: "itemName",
      headerName: "Item Name",
      width: 130,
      editable: true,
      description: "Item Name",
    },
    {
      field: "specification",
      headerName: "Specification",
      width: 130,
      editable: true,
      description: "Specification",
    },

    {
      field: "hsn_sac",
      headerName: "HSN/SAC",
      width: 130,
      editable: true,
      description: "HSN/SAC",
    },

    {
      field: "unit",
      headerName: "Unit",
      width: 130,
      editable: true,
      description: "Unit",
    },

    {
      field: "quantity",
      headerName: "Quantity",
      width: 140,
      editable: true,
      description: "Quantity",
    },
    {
      field: "rate",
      headerName: "Rate",
      width: 140,
      editable: true,
      description: "Rate",
    },
    {
      field: "preTaxAmount",
      headerName: "Pre Tax Amount",
      width: 140,
      editable: true,
      description: "Pre Tax Amount",
    },
    {
      field: "cgstRate",
      headerName: "CGST Rate",
      width: 140,
      editable: true,
      description: "CSGT Rate",
    },
    {
      field: "sgstRate",
      headerName: "SGST Rate",
      width: 140,
      editable: true,
      description: "SGST Rate",
    },
    {
      field: "igstRate",
      headerName: "IGST Rate",
      width: 140,
      editable: true,
      description: "ISGT Rate",
    },
    {
      field: "cessAmount",
      headerName: "Cess Rate",
      width: 140,
      editable: true,
      description: "Cess Rate",
    },
    {
      field: "cgstAmount",
      headerName: "CGST Amount",
      width: 140,
      editable: true,
      description: "CSGT Amount",
    },
    {
      field: "sgstAmount",
      headerName: "SGST Amount",
      width: 140,
      editable: true,
      description: "SGST Amount",
    },
    {
      field: "igstAmount",
      headerName: "IGST Amount",
      width: 140,
      editable: true,
      description: "ISGT Amount",
    },

    {
      field: "gstTaxAmount",
      headerName: "Gst Tax Amount",
      width: 70,
      editable: true,
      description: "Gst Tax Amount",
    },
    {
      field: "grossAmount",
      headerName: "Gross Amount",
      width: 100,
      editable: true,
      description: "Gross Amount",
    },
    {
      field: "uuid",
      headerName: "UUID",
      width: 140,
      editable: true,
      description: "UUID",
    },
    {
      field: "itemUniqueId",
      headerName: "Item Unique ID",
      width: 140,
      editable: true,
      description: "Item Unique ID",
      hideable: false
    },
  ];

  //   const handleEvent =(event)=>{
  //     window.open(invoiceImageURL, "_blank", "width=600,height=400");

  //   }

  const handelclick = () => {
    navigate("/tab");
  };
  const handelclick1 = () => {
    navigate("/aaa");
  };
  const handelclick2 = () => {
    navigate("/approvaltable");
  };


  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // For filter i used 2 more line
        //   const que= query(collection(db, "itemizedInvoiceData"), where("", "==", ""));
        //   const querySnapshot=await getDocs(que);
        const que= query(collection(db, "itemsDatabase"), orderBy('timeStamp',"desc"),
        // where("approvalStatus", "==", "financeApproved")
        );
        const querySnapshot=await getDocs(que);
      //  const querySnapshot = await getDocs(collection(db, "itemsDatabase"));
        const fetchedData = querySnapshot.docs.map((doc) => 
          ({
            ...doc.data(),
            id: doc.id,
            ref: doc.ref.path
          }));
          //doc.data());
        setItmizedDataTable(fetchedData);

        console.log("Data from Firestore:", fetchedData);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);
  console.log("tabledata", itmizedDataTable);
  console.log("tabldescriptionOfExpense", itmizedDataTable[0]?.invoiceImage);

  // const imgURL=itmizedDataTable.invoiceImage;

  return (
    <>
      <Box sx={{ marginBottom: "5px" }}>
        <Navbaar />
        <Layout/>
      </Box>
     
      <Box mb={0.5} sx={{ marginTop:"-112px",  display:"flex", justifyContent: "space-between", marginRight: "10px" }}>
      {/* <Box sx={{ color: "orange", fontSize:"22px", marginLeft:"10px",marginTop:"5px"}}>Item Master Table</Box>
      <Box sx={{ textAlign:"right",marginRight: "10px"}}> 
      <Button variant="text" color="warning" onClick={handelclick2}>
          Approval Master Table
        </Button>
        <Button variant="text" color="warning" onClick={handelclick}>
          Expense Table
        </Button>
        <Button variant="text" color="warning" onClick={handelclick1}>
         AI Item Table List
        </Button>
        </Box> */}
      </Box>

      <Box
        p={0.5}
        ml={30}
        sx={{
          height: 656,
          width: "83%",
          backgroundColor: "#ffffff",
          minHeight: "600px",
          maxHeight: "100%",
          "& .discuss": {
            backgroundColor: "#fff44f",
            color: "#1a3e72",
          },
          "& .accept": {
            backgroundColor: "#3CB371",
            color: "#EFEFEF",
          },
          "& .reject": {
            backgroundColor: "#DD6464",
            color: "#EFEFEF",
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row.id}
          rows={itmizedDataTable ? itmizedDataTable : ""}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[500]}
          components={{ Toolbar: GridToolbar }}
          rowHeight={40}
          //  onRowClick={handleEvent}
          // initialState={{
          //   sorting: {
          //     sortModel: [
          //       {
          //         field: 'submissionDate',
          //         sort: 'desc',
          //       },
          //     ],
          //   },
          // }}
          // getCellClassName={(params) => {
          //   if ( params.value === "Reject") {
          //     return 'reject';
          //   }
          //   if ( params.value === "Accept") {
          //     return 'accept';
          //   }
          //   if ( params.value === "Discuss") {
          //     return 'discuss';
          //   }
          // }}
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </>
  );
}

export default ItemizedTable;
